import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from '../styles';
import Grid from '@mui/material/Grid';
import { useRecordContext } from 'react-admin';

const OptionsField  = (props) => {
    const classes = useGlobalStyles();
    const record = useRecordContext(props);
    const code = props.code;

    return (
        <Grid container>
            <Grid item xs={12}>
      <span className={classes.gray}>
        {record.option.name} ({record.option.code})
      </span>
            </Grid>
            <Grid item xs={12}>
                {record.value} {code && '('+record.code+')'}
            </Grid>
        </Grid>
    )
};

OptionsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};

export {OptionsField};