import { defaultTheme } from 'react-admin';

export const Theme = {
    ...defaultTheme,
    palette: {
        primary: {
            main: '#830E43',
            light: '#830E43'
        },
        secondary: {
            main: '#830E43',
            light: '#830E43'
        }
    },
    components: {
        ...defaultTheme.components,
        MuiButton: {
            styleOverrides: {
                containedSecondary: {
                    backgroundColor: '#830E43',
                    padding: '8px 15px',
                    margin: '4px',
                    color: '#fff',
                    '&.Mui-disabled': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                    '&:hover': {
                        backgroundColor: 'rgb(230, 206, 217)',
                        color: '#000',
                    },
                }
            }
        }
    }
};
