import * as React from 'react'
import { Fragment } from 'react'
import {
    ArrayField,
    Datagrid,
    DatagridConfigurable,
    EditButton,
    FunctionField,
    List,
    ShowButton,
    TextField,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    useListController,
    BulkActionsToolbar,
    Loading,
    useGetOne,
    RecordContextProvider,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    Edit,
} from 'react-admin'
import ShowIcon from '@mui/icons-material/Visibility'
import { CustomPagination } from '../../layout'
import { getShipmentStateTranslation, ShipmentFilter } from './shipmentFilter'
import { ShipmentSidebar } from './shipmentSidebar'
import { ShipmentToolbar } from './shipmentEdit'

import {
    DownloadPDFButton,
    LabelButton,
    PrintButton,
    ShipButton,
    DeliverButton,
    AddOrRemoveOrderItemButton,
} from '../../buttons'
import { ProductField, ShippingField, ThumbnailField } from '../../fields'
import { ShipmentListPackaging } from './shipmentListPackaging'
import { useCallback, useState } from 'react'
import { Alert, AlertTitle, Checkbox, FormControlLabel, Grid } from '@mui/material'

export const ShipmentList = (props) => {
    const context = useListController(props);
    const matchPackaging = context.filterValues.printoscan && context.filterValues.printoscan === true;
    const [checkboxStates, setCheckboxStates] = useState({});
    const handleCheckboxChange = useCallback(
        (key) => {
            setCheckboxStates({
                ...checkboxStates,
                [key]: checkboxStates[key] ? !checkboxStates[key] : true
            });
        },
        [checkboxStates]
    );

    if (matchPackaging) {
        const shipmentsFromContext = context.data && context.selectedIds.map(selectedId => context.data.find(record => record.id === selectedId));

        return (
            <List
                title="Liste des expéditions"
                perPage={50}
                pagination={<CustomPagination/>}
                sort={{ field: 'id', order: 'DESC' }}
                filters={ShipmentFilter}
                filterDefaultValues={{}}
            >
                <BulkActionsToolbar selectedIds={context.selectedIds}>
                    <ShipmentBulkActionButtons alertCheckboxes={checkboxStates} />
                </BulkActionsToolbar>
                <ShipmentListPackaging />
                {context.selectedIds.length ? (
                <div style={{ bottom: 0, position: 'fixed', width: '85%', maxHeight:'300px', overflowY: 'scroll', background: '#fff', padding: '20px', border: '1px solid #000' }}>
                    {context.selectedIds.length === 1 ? <ShipmentGroupable id={context.selectedIds[0]} /> : ''}
                    {shipmentsFromContext && shipmentsFromContext.map((shipmentFromContext) => {
                        if (!shipmentFromContext) {
                            return '';
                        }

                        const hasReprint = shipmentFromContext.orderItems.some(orderItem => orderItem.orderItemOriginId);
                        const hasEnvelope = shipmentFromContext.orderItems.some(orderItem => orderItem.hasEnvelope);
                        if (hasReprint && typeof checkboxStates[`row-checkbox-print-` + shipmentFromContext.id] === 'undefined') {
                            checkboxStates[`row-checkbox-print-` + shipmentFromContext.id] = false;
                        }
                        if (hasEnvelope && typeof checkboxStates[`row-checkbox-envelope-` + shipmentFromContext.id] === 'undefined') {
                            checkboxStates[`row-checkbox-envelope-` + shipmentFromContext.id] = false;
                        }

                        return (
                            <>
                                {hasEnvelope ? (
                                    <Alert severity="warning">
                                        <AlertTitle>Enveloppes</AlertTitle>
                                        <FormControlLabel control={<Checkbox name={`row-checkbox-envelope` + + shipmentFromContext.id}
                                                                             checked={checkboxStates[`row-checkbox-envelope-` + shipmentFromContext.id] || false}
                                                                             onChange={(e) => {
                                                                                 handleCheckboxChange(`row-checkbox-envelope-` + shipmentFromContext.id);
                                                                             }} />} label={"Ajout " + (shipmentFromContext.orderItems.filter(orderItem => orderItem.hasEnvelope).map(orderItem => orderItem.options.find(optionValue => optionValue.option.code === 'enveloppe').value).join(' - ')) + " sur l'envoi sélectionné avec la sous-commande " + shipmentFromContext.orderItems[0].number} />
                                    </Alert>
                                )
                                : ''}

                                {hasReprint ? (
                                    <Alert severity="warning">
                                        <AlertTitle>Validation retirage</AlertTitle>
                                        <FormControlLabel control={<Checkbox name={`row-checkbox-reprint`}
                                                                         checked={checkboxStates[`row-checkbox-print-` + shipmentFromContext.id] || false}
                                                                         onChange={(e) => {
                                                                             handleCheckboxChange(`row-checkbox-print-` + shipmentFromContext.id);
                                                                         }} />} label={"Cet envoi avec la sous commande " + shipmentFromContext.orderItems[0].number + " contient au moins un retirage, avez vous bien fait contrôler la qualité à un chef d'équipe ?"} />
                                    </Alert>
                                )
                                : ''}
                            </>
                        )
                    })}
                </div>) : ''}
            </List>
        )
    } else {
        return (
            <List
                title="Liste des expéditions"
                perPage={25}
                pagination={<CustomPagination/>}
                sort={{ field: 'id', order: 'DESC' }}
                filters={ShipmentFilter}
                filterDefaultValues={{ shipmentState: 'ready' }}
                aside={<ShipmentSidebar/>}
                actions={<ShipmentListActionButtons/>}
            >
                <DatagridConfigurable bulkActionButtons={<ShipmentBulkActionButtons />}>
                    <TextField source="id"/>
                    <FunctionField source="state" label="Statut" render={record => getShipmentStateTranslation(record.state)}/>

                    <TextField source="carrier" label="Transp." sortable={false}/>
                    <FunctionField source="weight" label="Poids" render={record => Math.round((record.weight + Number.EPSILON) * 100) / 100 + 'kg'}
                                   sortable={false}/>
                    <ShippingField label="Exp/Liv"/>
                    <ArrayField source="orderItems" label="Sous-commandes">
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField source="number" label="Numéro" sortable={false} render={record => (
                                <div>{record.number}<br /><ShowButton label="" resource="order-items"/></div>
                            )}/>
                            <ThumbnailField label="Img"/>
                            <TextField source="quantity" label="Qté" sortable={false}/>
                            <ProductField label="Produit" center grayName/>
                            <ShippingField label="Exp/Liv"/>
                        </Datagrid>
                    </ArrayField>
                    <ShipmentActionButtons label="Actions" />
                </DatagridConfigurable>
            </List>
        )
    }
}

const ShipmentGroupable = props => {
    const { data: currentShipment, isLoading, error } = useGetOne('shipments', { id: props.id });

    if (isLoading || typeof currentShipment.orderItemsCanBeShippedWith === 'undefined') { return <Loading />; }
    if (error) { return <p>Error please retry.</p>; }

    return (
        <RecordContextProvider value={currentShipment}>
            <div>
                Sous commandes groupables:
                <ul>
                    {currentShipment.orderItemsCanBeShippedWith.length ? currentShipment.orderItemsCanBeShippedWith.map(orderItemCanBeShippedWith => {
                        return (
                            <li>
                                {orderItemCanBeShippedWith.number}
                                <ShowButton record={orderItemCanBeShippedWith} resource="order-items" label="Voir"/>
                                {orderItemCanBeShippedWith.variantCode}
                                <AddOrRemoveOrderItemButton record={orderItemCanBeShippedWith} label="Ajouter" type="add" shipmentId={currentShipment.id} />
                            </li>
                        )
                    }) : ' Aucune'}
                </ul>
                <Edit redirect={false} mutationMode="pessimistic" undoable={false} id={currentShipment.id} resource="shipments">
                    <SimpleForm toolbar={<ShipmentToolbar />}>
                        <Grid container rowSpacing={1} mb={1}>
                            <Grid item xs={3}>
                                <ReferenceInput label="Transp." source="carrierId" reference="carriers" validate={[required()]}>
                                    <SelectInput optionText="name" optionValue="id"/>
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput source="weight" label="Poids total (kg)" validate={[required()]} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput source="numberPackages" label="Nombre de colis" validate={[required()]} />
                            </Grid>
                        </Grid>
                        {/*<ReferenceInput label="Transp." source="carrierId" reference="carriers" validate={[required()]}>*/}
                        {/*    <SelectInput optionText="name" optionValue="id"/>*/}
                        {/*</ReferenceInput>*/}
                        {/*<TextInput source="id" style={{display:'none'}}/>*/}
                    </SimpleForm>
                </Edit>
            </div>
        </RecordContextProvider>
    );
}

const ShipmentBulkActionButtons = props => (
    <>
        <LabelButton {...props}/>
        <PrintButton {...props}/>
        <DownloadPDFButton {...props}/>
        <ShipButton {...props}/>
        <DeliverButton {...props}/>
    </>
)

const ShipmentListActionButtons = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton filters={ShipmentFilter} />
    </TopToolbar>
)
const ShipmentActionButtons = props => (
    <Fragment>
        <EditButton {...props} icon={<ShowIcon/>} label="voir" variant="containedSecondary" />
        <LabelButton {...props}/>
        <PrintButton {...props}/>
        <DownloadPDFButton {...props}/>
    </Fragment>
)
