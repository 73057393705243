import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from '../../styles';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useRecordContext } from 'react-admin';

export const SupplierIssueItemField  = (props) => {
  const record = useRecordContext(props);
  let items = record["items"];
  const classes = useGlobalStyles();

  return (
      <Grid container spacing={1}>
        {items.map(item => {
          return (
            <Grid item container xs={12}>
              <Grid item xs={12}>
                  <span className={`${classes.gray} ${classes.small}`}> Créé par {item["sender"]} le {moment(item["createdAt"]).format('DD/MM/YYYY HH:mm')}</span>
              </Grid>
              <Grid item xs={12}>
                {item["comment"]}
              </Grid>
            </Grid>
          )})}
      </Grid>
  )
};

SupplierIssueItemField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};
