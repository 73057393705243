import React from 'react'
import { useRecordContext } from 'react-admin'
import PropTypes from 'prop-types'
import { useGlobalStyles } from '../../styles'
import Grid from '@mui/material/Grid'
import { CustomTextField, ProductField, ThumbnailField, VariantField, OptionsField } from '../../fields'
import { OrderItemDownloadButton } from '../../buttons'
import { Address } from '../../layout'

export const OrderItemLayout = () => {
    const record = useRecordContext();
    const classes = useGlobalStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
                <span className={classes.title}>Sous commande n°{record['number']}</span> <span
                className={classes.gray}>(id {record['id']})</span>
            </Grid>
            <Grid container item xs={12} md={4} justify="flex-end">
                <OrderItemDownloadButton record={record}/>
            </Grid>
            <Grid item container md={3}>
                <ThumbnailField record={record}/>
            </Grid>
            <Grid item container md={9} spacing={3}>
                <Grid item xs={4}>
                    <CustomTextField source={<ProductField inline record={record}/>} label="Produit" record={record}/>
                </Grid>
                <Grid item xs={4}>
                    <CustomTextField source="quantity" label="Quantité" record={record}/>
                </Grid>
                <Grid item xs={4}>
                    <CustomTextField date label="Date d'expé prévue" source="estimatedShippedDate" record={record}/>
                </Grid>
                <Grid item xs={8}>
                    <CustomTextField source={<VariantField record={record}/>} label="Variante" record={record}/>
                </Grid>
                <Grid item xs={4}>
                    <CustomTextField date label="Date de livraison prévue" source="estimatedDeliveryDate"
                                     record={record}/>
                </Grid>
            </Grid>
            <Grid item container md={4} xs={6} direction="column" spacing={2}>
                <Grid item>
                    <span className={`${classes.subtitle} ${classes.bold}`}>Options Standard</span> <span
                    className={classes.gray}> (options) </span>
                </Grid>
                {record['options'].filter((option) => option.option.code !== 'quantity')
                    .map((option, i) => (
                            <Grid item key={i}>
                                <OptionsField key={i} record={option} code/>
                            </Grid>
                        )
                    )}
            </Grid>
            {record['userInputs'].length > 0 &&
            <Grid item container md={4} xs={6} direction="column" spacing={2}>
                <Grid item>
                    <span className={`${classes.subtitle} ${classes.bold}`}>Données variables</span> <span
                    className={classes.gray}> (userInputs) </span>
                </Grid>
                {record['userInputs'].filter((option) => option.option.code !== 'quantity')
                    .map((option, j) => (
                            <Grid item key={j}>
                                <OptionsField record={option}/>
                            </Grid>
                        )
                    )}
            </Grid>
            }
            {record['shippingAddress'] && <Grid item container md={4} xs={6} direction="column" spacing={2}>
                <Grid item>
                    <span className={`${classes.subtitle} ${classes.bold}`}>Adresse de Livraison</span> <span
                    className={classes.gray}> (shippingAddress) </span>
                </Grid>
                <Grid item>
                    <Address record={record}/>
                </Grid>
            </Grid>
            }
            <Grid item container xs={12} direction="column" spacing={2}>
                <Grid item xs={12}>
        <span
            className={`${classes.subtitle} ${classes.bold}`}>Liste des sous-commandes pouvant être envoyées avec</span>
                </Grid>
            </Grid>
        </Grid>
    )
};

OrderItemLayout.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
}
