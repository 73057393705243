import React from 'react'
import PropTypes from 'prop-types'
import { useGlobalStyles } from '../../styles';
import { Button, useDataProvider, useListContext, useRecordContext } from 'react-admin'
import GetAppIcon from '@mui/icons-material/GetApp'
import CircularProgress from '@mui/material/CircularProgress'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

export const ManufacturingDownloadButton = (props) => {
    const record = useRecordContext(props);
    const { selectedIds } = useListContext(props);
    const classes = useGlobalStyles();
    const [isDownload, setIsDownload] = React.useState()
    const handleClick = (value) => {
        setIsDownload(value)
    }

    const dataProvider = useDataProvider();

    const downloadFiles = (manufacturingIds) => {
        dataProvider.getMany('manufacturings', { ids: manufacturingIds } )
            .then(({ data }) => {
                const zip = new JSZip();
                for (const manufacturing of data) {
                    const folderName = manufacturing.id + '-' + manufacturing.reference;
                    const manufacturingZipFolder = zip.folder(folderName);
                    let unitDownloadDocuments = [];
                    if (manufacturing.gangRun && manufacturing.gangRun.documents && manufacturing.gangRun.documents.length) {
                        unitDownloadDocuments = manufacturing.gangRun.documents;
                    } else if (manufacturing.documents && manufacturing.documents.length) {
                        unitDownloadDocuments = manufacturing.documents;
                    }
                    if (unitDownloadDocuments.length === 0) {
                        unitDownloadDocuments = [];
                        for (const orderItem of manufacturing.orderItems) {
                            if (orderItem.documents && orderItem.documents.length) {
                                const orderItemDocumentsFinal = orderItem.documents.filter(doc => doc.type === 'final');
                                if (orderItemDocumentsFinal.length === 0) {
                                    unitDownloadDocuments.push({'type': 'nofile', 'name': orderItem.number + '-aucun-fichier.txt'})
                                } else {
                                    unitDownloadDocuments = unitDownloadDocuments.concat(orderItemDocumentsFinal);
                                }
                            }
                        }
                    }

                    if (unitDownloadDocuments.length === 0) {
                        unitDownloadDocuments.push({'type': 'nofile', 'name': manufacturing.id + '-aucun-fichier.txt'})
                    }

                    unitDownloadDocuments.map((doc, i) => {
                        if (doc.type === 'nofile') {
                            manufacturingZipFolder.file(doc.name, new Blob(['aucun fichier disponible'], {type: 'text/plain;charset=utf-8'}));
                        } else {
                            const blobPromise = fetch(doc.path, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': doc.mimeType,
                                },
                            })
                                .then((response) => response.blob());

                            manufacturingZipFolder.file(doc.name, blobPromise);
                        }

                        return i;
                    });
                }

                zip.generateAsync({ type: 'blob' })
                    .then((blob) => {
                        saveAs(blob, manufacturingIds.length > 1 ? 'manufacturing-multiple' + manufacturingIds.length + '-' + new Date().toISOString().replace(':', '_').replace('T', '_').replace('Z', '') + '.zip'
                            : 'manufacturing-' + manufacturingIds[0] + '.zip');
                        handleClick(false)
                    })
                ;
            });
    }

    let canDownload = false;
    if (record) {
        if (record.gangRun && record.gangRun.documents && record.gangRun.documents.length) {
            canDownload = true;
        } else if (record.documents && record.documents.length) {
            canDownload = true;
        } else if (record.orderItems && record.orderItems[0].documents && record.orderItems[0].documents.length) {
            canDownload = true;
        }
    } else if (selectedIds && selectedIds.length > 0) {
        canDownload = true;
    }

    return (
        <div onClick={(e) => handleClick(true)} className={classes.flex}>
            <div className={classes.wrapper}>
                <Button
                    startIcon={isDownload ? <CircularProgress size={12} /> : <GetAppIcon/>}
                    label="Fichiers"
                    disabled={!canDownload || isDownload}
                    onClick={e => {
                        handleClick(true)
                        e.stopPropagation()
                        downloadFiles(selectedIds ? selectedIds : [record.id])
                    }}
                />
            </div>
        </div>)
};

ManufacturingDownloadButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
}
