import { stringify } from 'query-string'
import {
    GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, UPDATE, UPDATE_MANY
} from 'react-admin'
import { SUPPLIER_API_URL, SUPPLIER_API_VERSION, SUPPLIER_API_FULL_URL } from '../config'
import inMemoryJWT from '../authProvider/inMemoryJWT'

export default function httpClient(type, resource, params) {
    if (type === GET_MANY) {
        return Promise.all(params.ids.map(id => httpClient(GET_ONE, resource, { 'id': id, ...params })))
            .then(responses => {
                const mergedResponses = [];
                for (var response of responses) {
                    mergedResponses.push(response.data);
                }
                return { data: mergedResponses};
            });
    }
    if (type === UPDATE_MANY) {
        return Promise.all(params.ids.map(id => httpClient(UPDATE, resource, { 'id': id, 'data': params.data })))
            .then(responses => {
                const mergedResponses = [];
                for (let response of responses) {
                    mergedResponses.push(response.data);
                }
                return { data: mergedResponses};
            })
    }

    let url = '';
    const options = {
        headers : new Headers({
            Accept: 'application/json',
        }),
    };

    switch (type) {
        case GET_LIST: {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;
            const query = {
                limit: perPage,
                page: page,
            };
            url = `${SUPPLIER_API_URL}${SUPPLIER_API_VERSION}/${resource}${params.urlFilters ? '/filters' : ''}?${stringify(query)}`;
            if (Object.entries(params.filter).length > 0) {
              for (const [fieldFilter, value] of Object.entries(params.filter)) {
                if (value instanceof Array) {
                  let i = 0;
                  for (const singleValue of value) {
                    url += `&filter[${fieldFilter}][${i}]=${singleValue}`;
                    i++;
                  }
                } else {
                  url += `&filter[${fieldFilter}]=${value}`;
                }
              }
            }
            if (field) {
                url += `&sort[${field}]=${order}`
            }
            break;
        }
        case GET_ONE: {
            switch (resource) {
                case 'order-items-shipped-with':
                    url = `${SUPPLIER_API_URL}${SUPPLIER_API_VERSION}/order-items/${params.id}`;
                    break;
                default:
                    url = `${SUPPLIER_API_URL}${SUPPLIER_API_VERSION}/${resource}/${params.id}`;
                    if (params.transition) {
                        url += `/${params.transition}`;
                    }
                    if (params.query && Object.entries(params.query).length > 0) {
                        let i = 0;
                        for (const [key, value] of Object.entries(params.query)) {
                            url += ((i === 0) ? '?' : '&') + `${key}=${value}`;
                            i++;
                        }
                    }
                    break;
            }
            break;
        }
        case GET_MANY_REFERENCE: {
            const {page, perPage} = params.pagination;
            const query = {
                limit: perPage,
                page: page,
            };
            switch (resource) {
                case 'order-items':
                    switch (params.target) {
                        case 'can-be-shipped-with':
                            url = `${SUPPLIER_API_URL}${SUPPLIER_API_VERSION}/order-items/${params.target}/${params.id}?${stringify(query)}`;
                            break;
                        default:
                            url = `${SUPPLIER_API_URL}${SUPPLIER_API_VERSION}/manufacturings/${params.id}/${params.target}?${stringify(query)}`;
                            break;
                    }
                    break;
                default:
                    break;
            }
            break;
        }
        case UPDATE:
            url = `${SUPPLIER_API_FULL_URL}/${resource}/${params.id}`;
            options.method = 'PUT';
            options.body = JSON.stringify(params.data);
            if (params.data && params.data.transition) {
                url += `/${params.data.transition}`;
            }
            break;
        default:
            break;
    }

    const fetchJsonData = (url, options) => {
        let headers;
        let response;
        return fetch(url, options)
            .then(res => {
                headers = res.headers;
                response = res;
                return res.json();
            })
            .then((json) => {
                if (response.status !== 200) {
                    return Promise.reject({status: response.status, statusText: response.statusText, message: json});
                }
                switch (type) {
                    case GET_LIST: {
                        return {
                            data: json,
                            total: parseInt(
                                headers.get('Total'), 10
                            )
                        }
                    }
                    case GET_ONE: {
                        return {
                            data: json
                        }
                    }
                    case GET_MANY_REFERENCE: {
                        return {
                            data: json,
                            total: parseInt(
                                headers.get('Total'), 10
                            )
                        }
                    }
                    case UPDATE: {
                        return {
                            data: json
                        }
                    }
                    default:
                        break;

                }
            });
    }

    const token = inMemoryJWT.getToken();

    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);

        return fetchJsonData(url, options);
    } else {
        inMemoryJWT.setRefreshTokenEndpoint(SUPPLIER_API_URL + '/token/refresh')

        return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
            if (gotFreshToken) {
                options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
            }

            return fetchJsonData(url, options);
        })
    }
}
