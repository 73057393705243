import * as React from 'react';
import { useCallback } from 'react';
import { useUpdate, useNotify, useRefresh, Button, useRecordContext } from 'react-admin';

import AddIcon from '@mui/icons-material/Add';
import EjectIcon from '@mui/icons-material/Eject';
import PropTypes from 'prop-types'

export const AddOrRemoveOrderItemButton = (props) => {
    const shipmentId = props.shipmentId;
    const label = props.label;
    const type = props.type;
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext(props);

    const [addOrRemoveOrderItemFromShipment, { isLoading }] = useUpdate('shipments', {}, {
        onSuccess: (response) => {
            notify(`order item ${type === 'add' ? 'added to ' : 'removed from '} shipment ${record.id}`, 'info', { smart_count: 1 }, false);
            refresh();
        },
        onError: (error) => {
            notify(
                `shipment ${shipmentId} edit link orderItems error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
                'warning'
            )
        },
    });
    const handleAddOrRemoveOrderItem = useCallback(
        event => {
            event.stopPropagation();
            const data = type === 'add' ? { addOrderItemIds: [record.id] } :  { removeOrderItemIds: [record.id] }
            addOrRemoveOrderItemFromShipment(
                'shipments',
                { id: shipmentId, data },
            );
        },
        [addOrRemoveOrderItemFromShipment, record, shipmentId, type]
    );
    const icon = type === 'add' ? <AddIcon /> : <EjectIcon />

    return <Button label={label} onClick={handleAddOrRemoveOrderItem} disabled={isLoading} startIcon={icon} />;
};

AddOrRemoveOrderItemButton.propTypes = {
    record: PropTypes.object,
    shipmentId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    type: PropTypes.string,
    label: PropTypes.string,
};
