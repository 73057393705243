import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from '../styles';
import { useRecordContext } from 'react-admin';
import { Tooltip } from '@mui/material';

const VariantField  = (props) => {
    const classes = useGlobalStyles();
    const record = useRecordContext(props);
    const center = props.center;

    return (
        <div className={center && classes.center}>
            <Tooltip title={'x'+record.quantity+' '+record["variantCode"]}>
                <span>x{record.quantity}&nbsp;{record["variantCode"]}</span>
            </Tooltip>
            <br/>
            <Tooltip title={record["variantName"]}>
            <span className={`${classes.gray} ${classes.small}`} style={{
                maxWidth: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}>
              {record["variantName"]}
            </span>
            </Tooltip>
        </div>
    )
};

VariantField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};

export {VariantField};