import * as React from 'react'
import {
    Show,
    SimpleShowLayout,
    TopToolbar,
    ReferenceManyField,
    TextField,
    Datagrid,
    ShowButton, useRecordContext, Pagination,
} from 'react-admin'
import { BackButton } from '../../buttons/backButton'
import { OrderItemLayout } from './orderItemLayout'
import { ProductField, ShippingField, ThumbnailField, VariantField } from '../../fields'
import { OrderItemDownloadButton } from '../../buttons'
import { LinkToRelatedShipments } from "../../buttons/orderItem/linkToRelatedShipments";

export const OrderItemShow = () => (
    <Show actions={<BackActions/>}>
        <SimpleShowLayout>
            <OrderItemLayout />
            <ReferenceManyField label={false} reference="order-items" target="can-be-shipped-with" pagination={<Pagination />}>
                <Datagrid bulkActionButtons={false} empty="Aucune sous-commande">
                    <TextField source="number" label="Numéro" sortable={false}/>
                    <ThumbnailField label="Vignette"/>
                    <ProductField label="Product" center grayName/>
                    <VariantField label="Variante"/>
                    <ShippingField label="Exp/Liv"/>
                    <TextField source="quantity" label="Quantité" sortable={false}/>
                    <ShowButton label="Voir"/>
                    <OrderItemDownloadButton/>
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
)

const BackActions = () => {
    const record = useRecordContext();

    return (
        <TopToolbar>
            <BackButton/>
            <LinkToRelatedShipments record={record} />
        </TopToolbar>
    )
}

