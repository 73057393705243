import React from 'react'
import PropTypes from 'prop-types'
import { useGlobalStyles } from '../../styles';
import {Button, useListContext, useRecordContext} from 'react-admin'
import GetAppIcon from '@mui/icons-material/GetApp'
import CircularProgress from '@mui/material/CircularProgress'
import { SUPPLIER_API_FULL_URL } from '../../config'
import { mergeBetweenPDF } from '../../utils/pdfProvider'
import { saveSync } from 'save-file'
import { saveAs } from 'file-saver'
import inMemoryJWT from '../../authProvider/inMemoryJWT'

export const ManufacturingPDFDownloadButton = (props) => {
    const record = useRecordContext(props);
    const { selectedIds } = useListContext(props);
    const labelButton = props.labelButton;
    const classes = useGlobalStyles();
    const [isDownload, setIsDownload] = React.useState()
    const handleClick = (value) => {
        setIsDownload(value)
    }

    const token = inMemoryJWT.getToken()
    const files = []
    const downloadFile = () => {
        if (record) {
            downloadSingleFile(record.id)
        } else {
            downloadMultipleFiles()
        }
    }

    const downloadMultipleFiles = () => {
        var promises = selectedIds.map(id =>
            fetch(`${SUPPLIER_API_FULL_URL}/manufacturings/${id}.pdf`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }).then((response) => response.blob())
                .then((blob) => {
                    let file = new File([blob], `manufacturing-${id}.pdf`)
                    return files.push(file)
                })
        )

        Promise.all(promises).then(() =>

            mergeBetweenPDF(files)
                .then((res) => {
                    if (res && res.hasOwnProperty('pdfFile')) {
                        if (res.pdfFile) {
                            if (res.pdfNotMergedList.length !== files.length) {
                                const fileName = 'manufacturings-' + new Date().toISOString().replace(':', '_').replace('T', '_').replace('Z', '') + '.pdf'
                                saveSync(res.pdfFile, fileName)
                            }
                        }

                    }
                }).finally(() => files.splice(0, files.length)))


    }


    const downloadSingleFile = (invoiceId) => {
        fetch(`${SUPPLIER_API_FULL_URL}/manufacturings/${invoiceId}.pdf`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(async response => await response.blob())
            .then((blob) => {
                saveAs(new Blob([blob], { type: 'application/pdf' }), `manufacturing-${invoiceId}`);
                handleClick(false)
            })
    }

    const canDownload = record || (selectedIds && selectedIds.length > 0);

    return (
        <div onClick={(e) => handleClick(true)} className={classes.flex}>
            <div className={classes.wrapper}>
                <Button
                    startIcon={isDownload ? <CircularProgress color={classes.gray}/> : <GetAppIcon/>}
                    label={labelButton ? labelButton : 'Fiche de Fab.'}
                    disabled={!canDownload || isDownload}
                    onClick={(e) => {
                        handleClick(true)
                        e.stopPropagation()
                        downloadFile()
                        handleClick(false)
                    }}
                />
                {isDownload && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </div>
        </div>

    )
};

ManufacturingPDFDownloadButton.propTypes = {
    label: PropTypes.string,
}
