import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from '../../styles';
import { first } from 'lodash';
import { useRecordContext } from 'react-admin';

function getFirstAvailableThumbnailFromDocuments(documents)  {
    if (documents) {
        for (const document of documents) {
            if (document.thumbnailDocuments && document.thumbnailDocuments.length) {
                return first(document.thumbnailDocuments);
            }
        }
    }

    return null;
}

export const ManufacturingThumbnailField = (props) => {
    const record = useRecordContext(props);
    const marge = props.marge;
    const classes = useGlobalStyles();
    const gangRunThumbnail = record.gangRun && record.gangRun.documents ? getFirstAvailableThumbnailFromDocuments(record.gangRun.documents) : null;
    const manufacturingThumbnail = record.documents ? getFirstAvailableThumbnailFromDocuments(record.documents) : null;
    const orderItemThumbnail = record.orderItems ? getFirstAvailableThumbnailFromDocuments(first(record.orderItems).documents) : null;
    const thumbnail = gangRunThumbnail || manufacturingThumbnail || orderItemThumbnail;
    if (!thumbnail) {
        return <div><span>Pas d'aperçu disponible</span></div>;
    }

    return <div><img src={thumbnail.path} alt={thumbnail.name}
                className={marge ? classes.thumbnailMarge : classes.thumbnail}/></div>;
};

ManufacturingThumbnailField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
