import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from '../styles';
import Grid from '@mui/material/Grid';
import { CustomTextField, ManufacturingThumbnailField } from './index';
import {useRecordContext} from "react-admin";

export const ManufacturingField  = (props) => {
    const classes = useGlobalStyles();
    const record = useRecordContext(props);

    return (
        <Grid container spacing={3}>
            <Grid item xs={10}>
                <span className={classes.title}>Fabrication n°{record["id"]}</span> <span className={classes.gray}>({record["reference"]})</span>
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={12}>
                <ManufacturingThumbnailField record={record} marge/>
            </Grid>
            <Grid item xs={6}>
                <CustomTextField source="state" label="Etat" record={record} />
            </Grid>
            <Grid item xs={6}>
                <CustomTextField source="orderItemCount" label="Nombre de sous commandes" record={record} />
            </Grid>
            <Grid item xs={6}>
                <CustomTextField date source="createdAt" label="Date de création de la fab" record={record} />
            </Grid>
            <Grid item xs={6}>
                <CustomTextField date source="nearestShippingAt" label="Date de livraison la plus proche" record={record} />
            </Grid>
            <Grid item xs={12}>
                <span className={`${classes.subtitle} ${classes.bold}`}>Liste des sous-commandes</span>
            </Grid>
        </Grid>
    )
};

ManufacturingField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
