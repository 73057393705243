import * as React from 'react'
import { Fragment } from 'react'
import {
    ArrayField,
    ArrayInput,
    Edit,
    Datagrid,
    DateField,
    Labeled,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    ShowButton,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useRecordContext,
    FunctionField,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Alert, Grid, Typography } from '@mui/material'
import {
    ProductField,
    ShippingField,
    ThumbnailField, VariantField
} from '../../fields'
import { AddOrRemoveOrderItemButton, LabelButton, PrintButton, DownloadPDFButton, DeliverButton, ShipButton } from '../../buttons'
import { BackButton } from '../../buttons/backButton'
import { Address } from '../../layout';
import { getShipmentStateTranslation } from "./shipmentFilter";
import {CrisisAlert, Inventory} from "@mui/icons-material";

const ShipmentTitle = () => {
    const record = useRecordContext();

    return <span>Expédition #{record ? record.id : ''}</span>
}

const useStyles = makeStyles(theme => ({
    arrayStrongLabel: {
        fontWeight: 'bold',
        marginTop: '8px',
    }
}))

const ArrayFieldWithLabel = props => (
    <div>
        <Typography variant="h6" gutterBottom className={useStyles().arrayStrongLabel}>{props.label}</Typography>
        <ArrayField {...props} addLabel={false} />
    </div>
)

export const ShipmentEdit = () => {
    const { id } = useParams();

    return (
        <Edit redirect={false} actions={<ShipmentActions/>} mutationMode="pessimistic" undoable={false} title={<ShipmentTitle/>}>
            <SimpleForm toolbar={<ShipmentToolbar />}>
                <Grid container rowSpacing={1} mb={1}>
                    <Grid item xs={3}>
                        <Labeled>
                            <FunctionField source="state" label="Statut" render={record => getShipmentStateTranslation(record.state)}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled>
                            <DateField source="estimatedShippedDate" label="Date d'exp. estimée" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Labeled>
                            <DateField source="estimatedDeliveryDate" label="Date de liv. estimée" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                        <Address />
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} mb={1}>
                    <Grid item xs={3}>
                        <ReferenceInput label="Transp." source="carrierId" reference="carriers" validate={[required()]}>
                            <SelectInput optionText="name" optionValue="id"/>
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput source="weight" label="Poids total (kg)" validate={[required()]} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput source="numberPackages" label="Nombre de colis" validate={[required()]} />
                    </Grid>
                </Grid>
                <ArrayInput source="packages" label="colis" draggable={false}>
                    <SimpleFormIterator disableAdd disableRemove inline disableReordering>
                        <TextInput disabled source="id" label="ID" />
                        <TextInput disabled source="weight" label="Poids (kg)" />
                        <TextInput source="tracking" label="Tracking" />
                        <TextInput disabled source="trackingUrl" label="URL" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ShipmentToolbar />
                <Grid container rowSpacing={1} mb={1}>
                    <Grid item xs={2}>
                        <Labeled>
                            <FunctionField label="Publicité" render={record => record.orderItems.every(orderItem => orderItem.canSendPrintoclockAdvertising) ? <Alert severity="error" icon={<Inventory sx={{ color: record.orderItems[0].adPackageCampaign.color }} />}>{ record.orderItems[0].adPackageCampaign.name }</Alert> : 'Pas de pub'}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={2}>
                        <Labeled>
                            <FunctionField label="finition" render={record => record.orderItems.some(orderItem => orderItem.hasFinishing) ? <Alert severity="info">Finition présente sur au moins une commande</Alert> : 'Pas de finition'}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={2}>
                        <Labeled>
                            <FunctionField label="Enveloppe" render={record => record.orderItems.some(orderItem => orderItem.hasEnvelope) ? <Alert severity="warning">Au moins une commande nécessite des enveloppes</Alert> : 'Pas d\'enveloppe'}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={2}>
                        <Labeled>
                            <FunctionField label="Retirage" render={record => record.orderItems.some(orderItem => orderItem.orderItemOriginId) ? <Alert severity="warning">Au moins une commande est un retirage</Alert> : 'Non'}/>
                        </Labeled>
                    </Grid>
                </Grid>
                <ArrayFieldWithLabel source="orderItems" label="Sous commandes à envoyer">
                    <Datagrid bulkActionButtons={false}  empty="Aucune commande, veuillez nous contacter, ce cas n'est pas normal.">
                        <TextField source="number" label="Numéro" sortable={false}/>
                        <FunctionField label="Retirage" render={record => record.orderItemOriginId ? <CrisisAlert color="primary" /> : 'Non'}/>
                        <ThumbnailField label="Vignette"/>
                        <ProductField label="Product" center grayName/>
                        <VariantField label="Variant" center grayName/>
                        <ShippingField label="Exp/Liv"/>
                        <TextField source="quantity" label="Quantité" sortable={false}/>
                        <ShowButton resource="order-items" label="Voir"/>
                        <AddOrRemoveOrderItemButton label="Enlever" type="remove" shipmentId={id}/>
                    </Datagrid>
                </ArrayFieldWithLabel>
                <ArrayFieldWithLabel source="orderItemsCanBeShippedWith" label="Autres Sous commandes regroupables">
                    <Datagrid bulkActionButtons={false} empty="Aucune commande">
                        <TextField source="number" label="Numéro" sortable={false}/>
                        <FunctionField label="Retirage" render={record => record.orderItemOriginId ? <CrisisAlert /> : 'Non'}/>
                        <ThumbnailField label="Vignette"/>
                        <ProductField label="Product" center grayName/>
                        <VariantField label="Variant" center grayName/>
                        <ShippingField label="Exp/Liv"/>
                        <TextField source="quantity" label="Quantité" sortable={false}/>
                        <ShowButton resource="order-items" label="Voir"/>
                        <AddOrRemoveOrderItemButton label="Ajouter" type="add" shipmentId={id}/>
                    </Datagrid>
                </ArrayFieldWithLabel>
            </SimpleForm>
        </Edit>
    )
}

const ShipmentActions = ({ data }) => {
    return (
        <TopToolbar>
            <BackButton/>
            <LabelButton record={data}/>
            <PrintButton record={data}/>
            <DownloadPDFButton record={data}/>
            <ShipButton record={data}/>
            <DeliverButton record={data}/>
        </TopToolbar>
    )
}

export const ShipmentToolbar = props => (
    <Toolbar {...props}>
        <Fragment/>
        <SaveButton label="Appliquer les modifications"/>
    </Toolbar>
)
