import * as React from 'react'
import { FilterList, FilterListItem, SavedQueriesList } from 'react-admin'
import { Card as MuiCard, CardContent } from '@mui/material'
import { withStyles } from '@mui/styles'
// import { useEffect } from 'react'

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard)

// const ManufacturingProductCodeFilterList = (props) => {
//     const dataProvider = useDataProvider()
//     const [loading, setLoading] = React.useState(true)
//     const [filters, setFilters] = React.useState()
//     const [error, setError] = React.useState(false)
//     const [changeFilter, setChangeFilter] = React.useState(false)
//     const filter = props.currentFilter
//     const defaultFilter = props.defaultFilters
//
//     useEffect(() => {
//         dataProvider
//             .getList('manufacturing', {
//                 urlFilters: 1,
//                 filter: ((!changeFilter) ? defaultFilter : filter),
//                 pagination: {page: 1, perPage: 100},
//                 sort: {}
//             })
//             .then(({ data }) => {
//                 setFilters(data)
//                 setLoading(false)
//             }).catch(error => {
//             setError(true)
//             setLoading(false)
//         })
//         setChangeFilter(true)
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [filter])
//
//     if (loading) return <Loading/>
//     if (error) return <Error/>
//     if (!filters) return null
//
//     return (
//         <FilterList label="Produits">
//             {filters.map(product => {
//                 return <FilterListItem
//                     label={product.name}
//                     key={product.code}
//                     value={{ productCode: product.code }}
//                 />
//             })}
//         </FilterList>
//     )
// }

const ManufacturingTypeFilterList = () => {
    const types = [
        { name: 'numerique', label: 'Numérique', value: {type: ['FAB_NUM']} },
        { name: 'offset', label: 'Offset', value: {type: ['FAB_OFF']} },
        { name: 'signaletique', label: 'Signalétique', value: {type: ['FAB_SIGNA']} },
        { name: 'external', label: 'Externe', value: {type: ['FAB_EXT']} },
    ]

    return (
        <FilterList label="Type">
            {types.map(manufacturingType => {
                return <FilterListItem
                    label={manufacturingType.label}
                    key={manufacturingType.name}
                    value={manufacturingType.value}
                />
            })}
        </FilterList>
    )

}

export const manufacturingStateList = [
    { name: 'scheduled', label: 'En attente' },
    { name: 'declined', label: 'Refusé' },
    { name: 'accepted', label: 'Accepté' },
    { name: 'completed', label: 'Complété' },
    { name: 'cancelled', label: 'Annulé' }
];

const ManufacturingStateFilterList = () => {
    return (
        <FilterList label="Etat">
            {manufacturingStateList.map(manufacturingState => {
                return <FilterListItem
                    label={manufacturingState.label}
                    key={manufacturingState.name}
                    value={{ state: [manufacturingState.name] }}
                />
            })}
        </FilterList>
    )

}

export const ManufacturingSidebar = (props) => (
    <Card>
        <CardContent>
            <SavedQueriesList />
            <ManufacturingStateFilterList/>
            <ManufacturingTypeFilterList/>
            {/*<ManufacturingProductCodeFilterList {...props} />*/}
        </CardContent>
    </Card>
)