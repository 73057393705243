import * as React from 'react'
import {
    EditView,
    EditController,
    SimpleForm,
    TextInput,
    BooleanInput,
    Toolbar,
    SaveButton
} from 'react-admin'

const SupplierIssueEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
)

export const SupplierIssueEdit = (props) => (
    <EditController {...props}>
        {(controllerProps) => (
            <EditView
                {...controllerProps} {...props}
            >
                <SimpleForm toolbar={<SupplierIssueEditToolbar/>}>
                    <TextInput multiline source="comment" initialValue="Tapez ici votre réponse"/>
                    {controllerProps.record.canClose && <BooleanInput source="close" label="Cloturer le SAV ?"/>}
                    <BooleanInput source="inProgressSupplier" label="En cours de traitement ?"/>
                </SimpleForm>
            </EditView>
        )}
    </EditController>
)

