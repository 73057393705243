import * as React from 'react';
import { useCallback } from 'react';
import { useNotify, Button, useListContext, useRecordContext, useDataProvider, usePermissions } from 'react-admin'
import GetAppIcon from '@mui/icons-material/GetApp'
import { openPDF } from './labelButton'
import { useMutation } from 'react-query'

export const DownloadPDFButton = (props) => {
    const notify = useNotify();
    const record = useRecordContext(props);
    const { selectedIds, data } = useListContext(props);
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();

    const { mutate, isLoading } = useMutation(
        (params) => dataProvider.getMany('shipments', params).then(({ data }) => {
            for (const updatedRecord of data) {
                openPDF(updatedRecord)
                notify(`shipment ${updatedRecord.id} label opening`, 'info', { smart_count: 1 }, false);
            }
        }).catch((error) => {
            notify(
                `shipment label opening ${record ? record.id : ''} error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
            );
        })
    );

    const handlePrint = useCallback(
        event => {
            event.stopPropagation();
            mutate({ ids: selectedIds && selectedIds.length ? selectedIds : [record.id] });
        },
        [mutate, record, selectedIds]
    );
    const noTracking = (!selectedIds || selectedIds.length === 0 || !data || selectedIds.some(selectedId => !data.find(({ id, packages }) => id === selectedId && packages && packages.every(shipmentPackage => shipmentPackage.tracking))))
        && (!record || !record.packages || record.packages.some(shipmentPackage => !shipmentPackage.tracking));
    const transportLabelAccess = permissions && permissions.transportLabelAccess;

    return <div><Button label="PDF" variant="containedSecondary" onClick={handlePrint} disabled={!transportLabelAccess || noTracking || isLoading} startIcon={<GetAppIcon/>}/></div>;
};
