import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { useRecordContext } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export const ManufacturingProductNameField = ( props ) => {
  const record = useRecordContext(props);
  const classes = useStyles();
  const productNames = [];

  if (record.orderItems) {
    record.orderItems.map(orderItem => {
      if (!productNames.includes(orderItem.productName)) {
        return productNames.push(orderItem.productName);
      }
      return null;
    });
  }

  return (
    <div className={classes.root}>
      {productNames.map((product, index) => {
        return <Chip key={index} label={product}/>
      })}
    </div>
  )

};

ManufacturingProductNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};
