import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

export const styles = {
    gray: { color: '#b5b5b3'},
    center: {textAlign: 'center'},
    small: {fontSize: 0.775+'em'},
    title: {
        fontSize: 1.5+'em',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 1.4+'em',
    },
    thumbnail: {
        maxWidth: 150+'px',
        maxHeight: 150+'px'
    },
    bold: {
        fontWeight: 'bold'
    },
    iconDashboard: {
        color: '#b5b5b3',
        fontSize: '4em'
    },
    thumbnailMarge: {
        maxWidth: 150+'px',
        maxHeight: 150+'px',
        margin: '0 10px',
    },
    wrapper: {
        position: 'relative',
    },
    flex: {
        display: 'flex'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}

export const useGlobalStyles = makeStyles(styles);
