import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SENTRY_DSN } from './config';

Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NODE_ENV,
});

let barcode = '';
let reading = false;

const setNativeValue = function(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
    } else {
        valueSetter.call(element, value);
    }
}

document.addEventListener('successPrint', e => {
    // Reset barcode on success print if in printoscan view.
    if (document.location && document.location.hash && document.location.hash.includes('printoscan')) {
        const input = document.getElementById('itemNumber');
        if (input) {
            setNativeValue(input, '');
            input.dispatchEvent(new Event('input', { bubbles: true }));
        }
    }
});

document.addEventListener('keypress', e => {
    // Usually scanners throw an 'Enter' key at the end of read.
    if (e.key === 'Enter') {
        console.log('?'+barcode+'?');
        if (barcode.length > 4) {
            console.log('barcode detected:' + barcode);
            if (!document.activeElement || document.activeElement.nodeName !== 'input') {
                const input = document.getElementById('itemNumber');
                if (input) {
                    setNativeValue(input, barcode);
                    input.dispatchEvent(new Event('input', { bubbles: true }));
                }
            }

            barcode = '';
        }
    } else {
        barcode += e.key;
    }

    if (!reading) {
        reading = true;
        setTimeout(() => {
            barcode = '';
            reading = false;
        }, 200);
    }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
