import { SUPPLIER_API_URL } from '../config'
import inMemoryJWT from './inMemoryJWT'
import restProvider from '../restProvider/restProvider'
import { GET_ONE } from 'react-admin'
import InMemoryJWT from "./inMemoryJWT";

const authProvider = {
    login: ({username, password}) => {
        const request = new Request(SUPPLIER_API_URL + '/login_check', {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        })
        inMemoryJWT.setRefreshTokenEndpoint(SUPPLIER_API_URL + '/token/refresh')
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(({token, refresh_token, expires}) => {
                return inMemoryJWT.setToken(token, expires, refresh_token);
            })
    },
    logout: () => {
        inMemoryJWT.ereaseToken()
        inMemoryJWT.setPermissions(null)

        return Promise.resolve()
    },
    checkError: (error) => {
        if (error.status === 401 || error.status === 403) {
            if (error.status === 401) {
                inMemoryJWT.ereaseToken()
                inMemoryJWT.setPermissions(null)
            }
            return Promise.reject()
        }
        return Promise.resolve()
    },
    checkAuth: () => {
        inMemoryJWT.setRefreshTokenEndpoint(SUPPLIER_API_URL + '/token/refresh')
        return inMemoryJWT.getRefreshedToken().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject()
        })
    },
    getPermissions: () => {
        let role = InMemoryJWT.getPermissions();
        if (!role) {
            const permissionsString = window.localStorage.getItem('permissionRoles');
            role = permissionsString && JSON.parse(permissionsString);
        }

        return role ? Promise.resolve(role) : Promise.reject();
    },
    getIdentity: async () => {
        const response = await restProvider(GET_ONE, 'business-consumers', { id: 'me' });
        const data = response.data;

        if (!data) {
            return Promise.reject();
        }
        inMemoryJWT.setPermissions({
            autoAccept: data.supplier.autoAccept,
            transportLabelAccess: data.supplier.transportLabelAccess,
            transitionShipmentAllowed: data.supplier.transitionShipmentAllowed,
            accessToSupplierIssue: data.supplier.accessToSupplierIssue,
            roles: data.roles,
            manufacturingAccess: data.manufacturingAccess,
        });
        window.localStorage.setItem('permissionRoles', JSON.stringify(InMemoryJWT.getPermissions()));

        return Promise.resolve({
            supplierId: data.supplier.id,
            fullName: data.username + ' (' + data.supplier.name + ')',
        });
    }
}

export default authProvider
