const inMemoryJWTManager = () => {
    let inMemoryJWT = null;
    let inMemoryPermissions = null;
    let isRefreshing = null;
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = '/refresh-token';
    let refreshTimeOutId;

    const setLogoutEventName = name => logoutEventName = name;
    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    // This countdown feature is used to renew the JWT before it's no longer valid
    // in a way that is transparent to the user.
    const refreshToken = (expires) => {
        // Validity period of the token in seconds, minus 5 seconds.
        const delay = (expires * 1000) - Date.now() - 5000;
        refreshTimeOutId = window.setTimeout(
            getRefreshedToken,
            delay > 0 ? delay : 0,
            true
        );
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    }

    // The method make a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
    const getRefreshedToken = (force = false) => {
        return waitForTokenRefresh().then(() => {
            if (!force && getToken()) {
                return true;
            }
            if (!getRefreshToken()) {
                return false;
            }
            const request = new Request(refreshEndpoint, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json' }),
                credentials: 'include',
                body: JSON.stringify({'refresh_token': getRefreshToken()}),
            });

            isRefreshing = fetch(request)
                .then((response) => {
                    if (response.status !== 200) {
                        ereaseToken();
                        global.console.log(
                            'Token renewal failure'
                        );
                        return { token: null };
                    }
                    return response.json();
                })
                .then(({ token, refresh_token, expires }) => {
                    if (token) {
                        setToken(token, expires, refresh_token);
                        return true;
                    }
                    ereaseToken();
                    return false;
                });

            return isRefreshing;
        });
    };


    const getToken = () => inMemoryJWT;
    const getRefreshToken = () => window.localStorage.getItem('refreshToken');

    const setToken = (token, delay, tokenRefresh) => {
        inMemoryJWT = token;
        window.localStorage.setItem('refreshToken', tokenRefresh);
        refreshToken(delay);

        return true;
    };

    const setPermissions = (permissions) => {
        inMemoryPermissions = permissions;

        return true;
    }

    const getPermissions = () => {
        return inMemoryPermissions;
    }

    const ereaseToken = () => {
        inMemoryJWT = null;
        window.localStorage.removeItem('refreshToken');
        abordRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now());

        return true;
    }

    // This listener will allow to disconnect a session of ra started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
            window.localStorage.removeItem('refreshToken');
        }
    });

    return {
        ereaseToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
        setPermissions,
        getPermissions,
    }
};

export default inMemoryJWTManager();
