import * as React from 'react'
import {List, Datagrid, TextField, DateField, ShowButton, BooleanField, ArrayField, FunctionField} from 'react-admin'
import { CustomPagination } from '../../layout'
import { ProductField, ShippingField, SupplierIssueFirstCommentField } from '../../fields'

export const SupplierIssueList = (props) => {
    return (
        <List
            {...props}
            title="Liste des SAV"
            perPage={25}
            pagination={<CustomPagination/>}
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField source="id"/>
                <DateField source="createdAt" label="Créé le"/>
                <DateField source="updatedAt" label="Mis à jour le"/>
                <SupplierIssueFirstCommentField label="Demande" sortable={false}/>
                <ArrayField source="orderItems" label="Sous-commandes">
                    <Datagrid bulkActionButtons={false}>
                        <FunctionField source="number" label="Numéro" sortable={false} render={record => (
                            <div>{record.number}<br /><ShowButton label="" resource="order-items"/></div>
                        )}/>
                        <TextField source="quantity" label="Qté" sortable={false}/>
                        <ProductField label="Produit" center grayName/>
                        <ShippingField label="Exp/Liv"/>
                    </Datagrid>
                </ArrayField>
                <TextField source="state" label="Statut"/>
                <BooleanField source="inProgressPoc" label="En cours PrintOclock"/>
                <BooleanField source="inProgressSupplier" label="En cours Fournisseur"/>
                <ShowButton {...props} label="Voir"/>
            </Datagrid>
        </List>
    )
}