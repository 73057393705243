import * as React from 'react'
import { Fragment } from 'react'
import {
    List,
    DatagridConfigurable,
    TextField,
    DateField,
    ShowButton,
    useListController,
    SelectColumnsButton,
    TopToolbar,
    FunctionField,
    FilterButton,
    usePermissions,
} from 'react-admin'
import { CustomPagination } from '../../layout'
import { ManufacturingSidebar, ManufacturingFilter } from '.'
import { manufacturingStateList } from './manufacturingSidebar'
import { CustomTextField, ManufacturingProductNameField, ManufacturingThumbnailField } from '../../fields'
import { AcceptOrDeclineButton, ManufacturingDownloadButton, ManufacturingPDFDownloadButton } from '../../buttons'
import { LinkToRelatedShipments } from '../../buttons/manufacturing/linkToRelatedShipments'

export const ManufacturingList = (props) => {
    const filter = useListController(props)
    const { permissions } = usePermissions();
    const autoAccept = permissions.autoAccept;
    const defaultFilters = (autoAccept) ? { state: ['accepted'] } : { state: ['scheduled'] }
    defaultFilters.type = []

    return (
        <List
            {...props}
            title="Liste des fabrications"
            perPage={25}
            pagination={<CustomPagination/>}
            sort={{ field: 'id', order: 'DESC' }}
            filters={ManufacturingFilter}
            filterDefaultValues={defaultFilters}
            actions={<ManufacturingListActionButtons />}
            aside={<ManufacturingSidebar currentFilter={filter.filterValues} defaultFilters={defaultFilters}/>}
        >
            <DatagridConfigurable bulkActionButtons={<ManufacturingBulkActionButtons/>}>
                <TextField source="id"/>
                <TextField source="externalFabricationId" label="ID Externe"/>
                <ManufacturingThumbnailField label="Vignette"/>
                <ManufacturingProductNameField label="Produits"/>
                <TextField source="type"/>
                <CustomTextField source="cost" label="Coût" divided/>
                <TextField source="orderItemCount" sortable={false} label="# sous commande(s)"/>
                <DateField source="nearestShippingAt" sortable={false} label="Exp le"/>
                <DateField source="scheduledAt" label="Reçue le"/>
                <FunctionField source="state" label="Statut" render={record => {
                    const state = manufacturingStateList.find(({ name } ) => name === record.state);
                    if (state) {
                        return state.label;
                    }

                    return record.state;
                }}/>
                <ManufacturingRowActionButtons label="Actions"/>
            </DatagridConfigurable>
        </List>
    )
}

const ManufacturingRowActionButtons = props => (
    <Fragment>
        <ShowButton {...props} label="voir"/>
        <ManufacturingPDFDownloadButton {...props} labelButton="PDF"/>
        <AcceptOrDeclineButton type="accept" {...props} label="accepter"/>
        <AcceptOrDeclineButton type="decline" {...props} label="refuser"/>
        <LinkToRelatedShipments {...props} />
    </Fragment>
)

const ManufacturingListActionButtons = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton filters={ManufacturingFilter} />
    </TopToolbar>
)

const ManufacturingBulkActionButtons = props => (
    <>
        <ManufacturingDownloadButton {...props}/>
        <ManufacturingPDFDownloadButton {...props}/>
        <AcceptOrDeclineButton label="Accepter" type="accept" {...props}/>
        <AcceptOrDeclineButton label="Refuser" type="decline" {...props}/>
    </>
)