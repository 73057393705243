import * as React from 'react';
import { useCallback } from 'react';
import {
    useNotify,
    Button,
    useListContext,
    useUpdateMany,
    useRecordContext,
    useUnselect,
    useRefresh,
    usePermissions,
} from 'react-admin'
import DeliverIcon from '@mui/icons-material/Label'

export const DeliverButton = (props) => {
    const record = useRecordContext(props);
    const notify = useNotify()
    const { resource, selectedIds, data } = useListContext(props);
    const unselect = useUnselect(resource);
    const refresh = useRefresh();
    const { permissions } = usePermissions();

    const [deliver, { loading }] = useUpdateMany(resource, {}, {
        onSuccess: (data) => {
            data.map(updatedRecord => {
                return notify(`shipment ${updatedRecord.id} delivered`, 'info', { smart_count: 1 }, false)
            })
            unselect(selectedIds);
            refresh();
        },
        onError: error => {
            notify(
                `shipment deliver ${record ? record.id : ''} error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
                'warning'
            )
        },
        onSettled: (data, error) => {
            if (error) {
                notify(
                    `shipment deliver ${data ? data.id : ''} error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
                    'warning'
                )
            } else if (data) {
                unselect([data.id]);
            }
        }
    });
    const handleDeliver = useCallback(
        event => {
            event.stopPropagation();
            deliver(
                'shipments',
                { ids: selectedIds && selectedIds.length ? selectedIds : [record.id], data: { transition: 'deliver' } },
            );
        },
        [deliver, record, selectedIds]
    );
    const transitionShipmentAllowed = permissions && permissions.transitionShipmentAllowed;
    const notAllowedDeliver = (!selectedIds || !data || selectedIds.length === 0 || selectedIds.some(selectedId => !data.find(({ id, canBeDelivered }) => id === selectedId && canBeDelivered)))
        && (!record || !record.canBeDelivered)

    return <div><Button label="Livrer" variant="containedSecondary" onClick={handleDeliver} disabled={!transitionShipmentAllowed || notAllowedDeliver || loading} startIcon={<DeliverIcon />} /></div>;
};
