import * as React from 'react';
import { useCallback } from 'react';
import { useUpdateMany, useNotify, Button, useRecordContext, useListContext, useUnselect } from 'react-admin';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PropTypes from 'prop-types'

export const AcceptOrDeclineButton = (props) => {
    const record = useRecordContext(props);
    const { resource, selectedIds, data } = useListContext(props);
    const unselect = useUnselect(resource);
    const label = props.label;
    const type = props.type;

    const notify = useNotify();

    const [addOrDeclineManufacturing, { isLoading }] = useUpdateMany('manufacturings', {},{
        onSuccess: (response) => {
            if (record) {
                notify(`manufacturing #${record.id} transition ${type} OK`, 'info', { smart_count: 1 }, false);
            } else {
                response.data.map(manufacturing => notify(`manufacturing #${manufacturing.id} transition ${type} OK`, 'info', { smart_count: 1 }, false));
            }
            unselect(selectedIds);
        },
        onError: (error) => {
            notify(
                `manufacturing ${record ? record.id : ''} transition ${type} error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
                'warning'
            )
        },
    });
    const handleAcceptOrDecline = useCallback(
        event => {
            event.stopPropagation();
            addOrDeclineManufacturing(
                'manufacturings',
                { ids: selectedIds && selectedIds.length ? selectedIds : [record.id], data: { transition: type } },
            );
        },
        [addOrDeclineManufacturing, record, type, selectedIds]
    );
    const icon = type === 'accept' ? <CheckCircleIcon /> : <HighlightOffIcon />
    const canApplyTransition = (selectedIds && selectedIds.length && data && selectedIds.every(selectedId => data.find(({ id, canBeAccepted, canBeDeclined }) => id === selectedId && (type === 'accept' ? canBeAccepted : canBeDeclined))))
        || (record && (type === 'accept' ? record.canBeAccepted : record.canBeDeclined));

    return <Button label={label} onClick={handleAcceptOrDecline} disabled={!canApplyTransition || isLoading} startIcon={icon} />;
};

AcceptOrDeclineButton.propTypes = {
    record: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.string,
};
