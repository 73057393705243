import * as React from 'react';
import { useCallback, useState } from 'react';
import {
    useNotify,
    Button,
    Confirm,
    useListContext,
    useUpdateMany,
    useRecordContext,
    useUnselect,
    useRefresh,
    usePermissions,
} from 'react-admin'
import LabelIcon from '@mui/icons-material/Label'
import { directPrintZPL } from './printButton'

export const openPDF = (shipment, type) => {
    const defaultType = shipment.carrier === 'ups' ? 'image/gif' : 'application/pdf';
    const documentType = type ? type : defaultType;
    if (shipment.labelContent) {
        openWindowBase64(shipment.labelContent, documentType);
    } else {
        for (const shipmentPackage of shipment.packages) {
            if (shipmentPackage.labelContent) {
                openWindowBase64(shipmentPackage.labelContent, documentType);
            }
        }
    }
}

const openWindowBase64 = (data, documentType) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: documentType + ';base64' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
}

export const LabelButton = (props) => {
    const record = useRecordContext(props);
    const notify = useNotify()
    const { resource, selectedIds, data } = useListContext(props);
    const unselect = useUnselect(resource);
    const refresh = useRefresh();
    const { permissions } = usePermissions();
    const [open, setOpen] = useState(false);
    const alertCheckboxes = props.alertCheckboxes;

    const [label, { isLoading }] = useUpdateMany('shipments', {}, {
        onSuccess: (data) => {
            data.map(updatedRecord => {
                notify(`shipment ${updatedRecord.id} labeled`, 'info', { smart_count: 1 }, false)
                if (!window.localStorage['defaultPrinterType']) {
                    return openPDF(updatedRecord)
                } else {
                    return directPrintZPL(updatedRecord, notify)
                }
            })
            unselect(selectedIds);
            refresh();
        },
        onError: error => {
            notify(
                `shipment label ${record ? record.id : ''} error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
                'warning'
            )
        },
        onSettled: (data, error) => {
            if (error) {
                notify(
                    `shipment label ${data ? data.id : ''} error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
                    'warning'
                )
            } else if (data) {
                unselect([data.id]);
            }
        }
    });
    const handleClick = (e) => {
        if (alertCheckboxes && Object.values(alertCheckboxes).some((checked) => checked === false)) {
            setOpen(true);
        } else {
            handleConfirm(e);
        }
    }
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = (e) => {
        handleLabel(e);
        setOpen(false);
    };
    const handleLabel = useCallback(
        event => {
            event.stopPropagation();
            label(
                'shipments',
                { ids: selectedIds && selectedIds.length ? selectedIds : [record.id], data: { transition: 'label', labelType: window.localStorage['defaultPrinterType'] ? 'zpl' : 'pdf' } },
            );
        },
        [label, record, selectedIds]
    );
    const transitionShipmentAllowed = permissions && permissions.transitionShipmentAllowed;
    const transportLabelAccess = permissions && permissions.transportLabelAccess;

    const notAllowedLabel = (!selectedIds || !data || selectedIds.length === 0 || selectedIds.some(selectedId => !data.find(({ id, canBeLabeled }) => id === selectedId && canBeLabeled)))
        && (!record || !record.canBeLabeled)

    return (<>
        <Button label="Etiqueter" variant="containedSecondary" onClick={handleClick} disabled={(!transitionShipmentAllowed && !transportLabelAccess) || notAllowedLabel || isLoading} startIcon={<LabelIcon />} />
        <Confirm
            isOpen={open}
            loading={isLoading}
            title="Etiqueter"
            content="Veuillez confirmer toutes les alertes avant d'etiqueter"
            onConfirm={handleClick}
            onClose={handleDialogClose}
        />
        </>)
}
