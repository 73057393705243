import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from '../styles';
import moment from 'moment';
import { useRecordContext } from 'react-admin';

export const ShippingField  = (props) => {
    const classes = useGlobalStyles();
    const record = useRecordContext(props);

    return (
        <div>
        <span className={`${classes.gray} ${classes.small}`}>
            EXP
        </span>
            <br/>
            {moment(record["estimatedShippedDate"]).format('DD/MM/YYYY')}
            <br/>
            <span className={`${classes.gray} ${classes.small}`}>
            LIV
        </span>
            <br/>
            {moment(record["estimatedDeliveryDate"]).format('DD/MM/YYYY')}
        </div>
    )
};

ShippingField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
