import * as React from 'react'
import {
    Show,
    SimpleShowLayout,
    TextField,
    Datagrid,
    ReferenceManyField,
    TopToolbar,
    ShowButton,
} from 'react-admin'
import {
    ManufacturingField,
    ProductField,
    ShippingField,
    ThumbnailField,
    VariantField,
} from '../../fields'
import { AcceptOrDeclineButton, ManufacturingDownloadButton, ManufacturingPDFDownloadButton, OrderItemDownloadButton } from '../../buttons'
import { BackButton } from '../../buttons/backButton'
import { LinkToRelatedShipments } from '../../buttons/manufacturing/linkToRelatedShipments'
import { CustomPagination } from '../../layout'

export const ManufacturingShow = () => (
    <Show actions={<ManufacturingActions />}>
        <SimpleShowLayout>
            <ManufacturingField/>
            <ReferenceManyField label={false} reference="order-items" target="order-items" pagination={<CustomPagination />}>
                <Datagrid bulkActionButtons={false} empty="Aucune sous-commande, veuillez nous contacter ce n'est pas possible">
                    <TextField source="number" label="Numéro" sortable={false}/>
                    <ThumbnailField label="Vignette"/>
                    <ProductField label="Product" center grayName/>
                    <VariantField label="Variante"/>
                    <ShippingField label="Exp/Liv"/>
                    <TextField source="quantity" label="Quantité" sortable={false}/>
                    <ShowButton resource="order-items" label="Voir"/>
                    <OrderItemDownloadButton />
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

const ManufacturingActions = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <BackButton />
            <ManufacturingPDFDownloadButton record={data} />
            <ManufacturingDownloadButton record={data} />
            <AcceptOrDeclineButton record={data} type="accept" label="Accepter"/>
            <AcceptOrDeclineButton record={data} type="decline" label="Refuser"/>
            <LinkToRelatedShipments record={data} />
        </TopToolbar>
    );
};