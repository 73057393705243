import * as React from 'react';
import { useCallback } from 'react';
import {
    useNotify,
    Button,
    useListContext,
    useUpdateMany,
    useRecordContext,
    useUnselect,
    useRefresh,
    usePermissions,
} from 'react-admin'
import ShipIcon from '@mui/icons-material/Label'

export const ShipButton = (props) => {
    const record = useRecordContext(props);
    const notify = useNotify()
    const { resource, selectedIds, data } = useListContext(props);
    const unselect = useUnselect(resource);
    const refresh = useRefresh();
    const { permissions } = usePermissions();

    const [ship, { isLoading }] = useUpdateMany('shipments', {}, {
        onSuccess: (data) => {
            data.map(updatedRecord => {
                return notify(`shipment ${updatedRecord.id} shipped`, 'info', { smart_count: 1 }, false)
            })
            unselect(selectedIds);
            refresh();
        },
        onError: error => {
            notify(
                `shipment ship ${record ? record.id : ''} error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
                'warning'
            )
        },
        onSettled: (data, error) => {
            if (error) {
                notify(
                    `shipment ship ${data ? data.id : ''} error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
                    'warning'
                )
            } else if (data) {
                unselect([data.id]);
            }
        }
    });
    const handleShip = useCallback(
        event => {
            event.stopPropagation();
            ship(
                'shipments',
                { ids: selectedIds && selectedIds.length ? selectedIds : [record.id], data: { transition: 'ship' } },
            );
        },
        [ship, record, selectedIds]
    );
    const transitionShipmentAllowed = permissions && permissions.transitionShipmentAllowed;
    const notAllowedShip = (!selectedIds || !data || selectedIds.length === 0 || selectedIds.some(selectedId => !data.find(({ id, canBeShipped }) => id === selectedId && canBeShipped)))
        && (!record || !record.canBeShipped);

    return <div><Button label="Expédier" variant="containedSecondary" onClick={handleShip} disabled={!transitionShipmentAllowed || notAllowedShip || isLoading} startIcon={<ShipIcon />} /></div>;
};
