import * as React from "react";
import { Card, CardContent, CardHeader, CardActions, Button } from '@mui/material';
import { useGlobalStyles } from '../styles';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CodeIcon from '@mui/icons-material/Code';
import Grid from '@mui/material/Grid';
import { ManufacturingCard } from '../card';
import { usePermissions } from 'react-admin';

export const Dashboard = ({props}) => {
    const classes = useGlobalStyles();
    const { permissions } = usePermissions();

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Bienvenue sur SupplierAdmin" disableTypography={true} classes={{root: classes.title}}/>
            <CardContent classes={{root: classes.gray}}>
              Ceci est l’espace fournisseur Printoclock, il permet de gérer les fabrications que nous vous envoyons, mais aussi d’imprimer les étiquettes de transports, de gérer les SAV ou encore la facturation. Toutes les actions que vous retrouvez sur cet espace sont également disponibles par API pour intégration dans vos systèmes.
            </CardContent>
            <CardActions>
              <Grid container justify="flex-end">
                <Grid item xs={6} md={3} className={classes.center}>
                  <Button size="small" startIcon={<ScheduleIcon/>} target="_blank" color="primary" href="https://www.printoclock.com">
                    PrintOclock.com
                  </Button>
                </Grid>
                <Grid item xs={6} md={3} className={classes.center}>
                  <Button size="small" startIcon={<CodeIcon/>} target="_blank" color="primary" href="https://developer.printoclock.com" >
                    Documentation API
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
            {permissions && permissions.manufacturingAccess ? <ManufacturingCard/> : ''}
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
                <b>Changelog 12/04/2023:</b>
                <ul>
                    <li>Support impression via reseau</li>
                </ul>
                <b>Changelog 14/02/2023:</b>
                <ul>
                    <li>Ajout du profil</li>
                    <li>Ajout filtres date sur expéditions</li>
                    <li>Vue conditionnement: ajout d'alertes</li>
                    <li>Corrections diverses: permissions en temps réel..etc</li>
                </ul>
                <b>Changelog 16/12/2022:</b>
                <ul>
                    <li>Ajout vue conditionnement</li>
                    <li>Ameliorations detection imprimante</li>
                </ul>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
              <CardContent>
                  <b>Connecter une imprimante en reseau</b>
                  <ul>
                      <li>
                          Saisissez l'adresse et le port de l'imprimante (Zebra utilise le port 9100 par defaut).
                      </li>
                  </ul>
              </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
              <CardContent>
                  <b>Connecter une imprimante en USB via un navigateur compatible WebUSB </b>(moteur chromium - blink: chrome, microsoft edge, opera, brave..etc)
                  <ul>
                      <li>
                          <u>Windows:</u> remplacer les pilotes de l'imprimante en WebUSB.
                          <p>
                              <ol>
                                  <li><a href="https://zadig.akeo.ie/">Telecharger et installer Zadig</a>.</li>
                                  <li>ouvrir en administrateur</li>
                                  <li>Options > List all devices</li>
                                  <li>selectionner l'imprimante dans la liste</li>
                                  <li>install (replace) driver</li>
                                  <li>Relancer le navigateur</li>
                                  <li>L'imprimante USB ne peut être utilisée qu'en WebUSB et est donc réservée pour une utilisation par l'interface supplier de PrintOClock.
                                      <br /><em>Vous pouvez reinstaller les pilotes windows dans le gestionnaire de peripheriques pour revenir en arrière ou reutiliser zadig.</em>
                                  </li>
                              </ol>
                          </p>
                      </li>
                      <li><u>Mac OS:</u> ne pas installer de pilotes, utiliser un navigateur moteur chromium (c'est plug & play normalement)</li>
                      <li><u>Linux:</u> ne pas installer de pilotes, ajouter une udev rule pour autoriser le navigateur à voir l'imprimante, utiliser un navigateur moteur chromium.</li>
                  </ul>
              </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
};

