import * as React from 'react'
import { Admin, Resource, Login } from 'react-admin'
import authProvider from './authProvider/authProvider'
import { ManufacturingShow, ManufacturingList } from './actions/manufacturing'
import { ShipmentEdit, ShipmentList } from './actions/shipment'
import { SupplierIssueCreate, SupplierIssueEdit, SupplierIssueList, SupplierIssueShow } from './actions/supplierIssue'
import restProvider from './restProvider/restProvider'
import { GlobalLayout, Theme, Dashboard } from './layout'
import { OrderItemList, OrderItemShow } from './actions/orderItem'
import PrintIcon from '@mui/icons-material/Print'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ForumIcon from '@mui/icons-material/Forum'

const MyLoginPage = () => (
    <Login backgroundImage="background.jpg"/>
)

const App = () => {
    return (
        <Admin dashboard={Dashboard}
               loginPage={MyLoginPage}
               layout={GlobalLayout}
               theme={Theme}
               dataProvider={restProvider}
               authProvider={authProvider}
               title="Supplier Admin - PrintOclock"
        >
            {(permissions) => [
                permissions && permissions.manufacturingAccess ?
                    <Resource
                        name="manufacturings"
                        list={ManufacturingList}
                        show={ManufacturingShow}
                        options={
                            { label: 'Fabrications' }
                        }
                        icon={<PrintIcon/>}
                    /> : null,
                <Resource
                    name="shipments"
                    list={ShipmentList}
                    edit={ShipmentEdit}
                    options={
                        { label: 'Expéditions' }
                    }
                    icon={<LocalShippingIcon/>}
                />,
                permissions && permissions.accessToSupplierIssue ?
                    <Resource
                        name="supplier-issues"
                        list={SupplierIssueList}
                        show={SupplierIssueShow}
                        edit={SupplierIssueEdit}
                        create={SupplierIssueCreate}
                        options={
                            { label: 'S.A.V.' }
                        }
                        icon={<ForumIcon/>}
                    /> : null,
                <Resource name="order-items" show={OrderItemShow} list={OrderItemList}/>,
                <Resource name="order-items-shipped-with" show={OrderItemShow} list={OrderItemList}/>,
                <Resource name="carriers"/>,
                <Resource name="suppliers"/>,
                <Resource name="business-consumers"/>,]}
        </Admin>
    )
}

export default App