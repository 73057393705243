import React, { useCallback, useEffect, useState } from 'react'
import { Button, InputLabel, MenuItem, Select, Input, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print';
import { useNotify } from 'react-admin'

export const PrinterConfiguration = () => {
    // Handle migration from old schema.
    if (window.localStorage['defaultPrinter'] && !window.localStorage['defaultPrinterType']) {
        window.localStorage['defaultPrinterType'] = 'usb';
    }
    const [devices, setDevices] = useState([])
    const [printer, setPrinter] = useState(window.localStorage['defaultPrinterType'] === 'usb' ? window.localStorage['defaultPrinter'] : '')
    const [printerNetwork, setPrinterNetwork] = useState(window.localStorage['defaultPrinterType'] === 'network' ? window.localStorage['defaultPrinterNetwork'] : '')
    const [printerType, setPrinterType] = useState(window.localStorage['defaultPrinterType'] ? window.localStorage['defaultPrinterType'] : 'usb')
    const notify = useNotify()

    const getUSBDevices = useCallback(() => {
        setDevices(devices => [])
        if (!navigator.usb) {
            return ;
        }

        return navigator.usb.getDevices()
            .then(usbDevices => {
                usbDevices.forEach(device => {
                    setDevices(devices => [...devices, device])
                })
            })
    }, [])

    useEffect(() => {
        if (!navigator.usb) {
            return;
        }
        getUSBDevices().then(() => {
            setPrinter(window.localStorage['defaultPrinter'] ? window.localStorage['defaultPrinter'] : '')
        })
    }, [getUSBDevices])

    const savePrinterNetwork = () => {
        const inputPrinterNetwork = document.getElementById('printer-network-input').value;
        if (!inputPrinterNetwork.length) {
            notify('adresse invalide', 'warning', { smart_count: 1 }, false)
        } else {
            window.localStorage['defaultPrinterType'] = 'network';
            window.localStorage['defaultPrinterNetwork'] = inputPrinterNetwork;
            setPrinterNetwork(inputPrinterNetwork)
            notify(`enregistrement de l'imprimante réseau par defaut: OK`, 'info', { smart_count: 1 }, false)
        }
    }

    const connectPrinters = () => {
        if (!navigator.usb) {
            notify('veuillez utiliser un navigateur qui supporte WebUSB (moteur chromium: chrome, microsoft edge, opera...etc)', 'warning', { smart_count: 1 }, false);

            return;
        }
        navigator.usb.requestDevice({ filters: [] })
            .then(usbDevice => {
                getUSBDevices().then(() => {
                    notify(`mis à jour de la liste des imprimantes USB: OK`, 'info', { smart_count: 1 }, false)
                })
            })
            .catch(e => {
                notify(e.message, 'warning', { smart_count: 1 }, false)
            })
    }

    const handleChangeType = (event) => {
        setPrinterType(event.target.value)
    }

    const handleChangeNetwork = (event) => {
        setPrinterNetwork(event.target.value)
    }

    const handleChangeUSB = (event) => {
        setPrinter(event.target.value)
        window.localStorage['defaultPrinter'] = event.target.value
        window.localStorage['defaultPrinterType'] = 'usb';
        notify(`enregistrement de l'imprimante USB par defaut: OK`, 'info', { smart_count: 1 }, false)
    }

    return (
        <div>
            <div>
                <InputLabel id="printer-select-label">Imprimante</InputLabel>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="usb"
                        name="radio-buttons-group"
                        value={printerType}
                        onChange={handleChangeType}
                    >
                        <FormControlLabel value="usb" control={<Radio />} label="USB" />
                        <FormControlLabel value="network" control={<Radio />} label="Réseau" />
                    </RadioGroup>
                </FormControl>
                <Select id="printer-select" value={printer} onChange={handleChangeUSB} displayEmpty sx={{display: printerType === 'usb' ? 'block' : 'none'}}>
                    <MenuItem value="">Aucune</MenuItem>
                    {devices.map(device => <MenuItem key={device.productId} value={device.productId}>{device.productName ? device.productName : 'unknown device#' + device.productId}</MenuItem>)}
                </Select>
                <Input id="printer-network-input" placeholder="ip:port" value={printerNetwork} onChange={handleChangeNetwork} sx={{display: printerType === 'network' ? 'block' : 'none'}} />
            </div>
            <br/>
            <Button startIcon={<PrintIcon/>} variant="contained" color="primary" onClick={printerType === 'network' ? savePrinterNetwork : connectPrinters}>
                {printerType === 'network' ? 'Enregistrer' : 'Connecter'}
            </Button>
        </div>
    )
}
