import * as React from 'react'
import {
    Form,
    MenuItemLink,
    ReferenceInput,
    required,
    SelectInput,
    useResourceDefinitions,
    useSidebarState,
    useGetIdentity,
    SaveButton,
    useNotify,
    useUpdate,
    useRefresh,
    usePermissions,
} from 'react-admin'
import DefaultIcon from '@mui/icons-material/ViewList'
import AppsIcon from '@mui/icons-material/Apps'
import { makeStyles } from '@mui/styles'
import { PrinterConfiguration } from './printer'

const Menu = ({ onMenuClick, logout }) => {
    const [open] = useSidebarState();
    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
    const { data: identity } = useGetIdentity();
    const { permissions } = usePermissions();
    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate();

    const excludedResources = [
        'order-items',
        'order-items-shipped-with',
        'carriers',
        'suppliers',
        'business-consumers'
    ];
    const useStyles = makeStyles({
        footer: {
            border: 0,
            padding: '0 10px',
            position: 'fixed',
            bottom: 0,
            maxWidth: '200px',
        },
    });
    const onSubmitMe = (data) => {
        update(
            "business-consumers",
            { id: 'me', data: {supplier: data.supplierId} },
            { onSuccess: () => { notify('fournisseur mis à jour avec succès.'); refresh(); } }
        );
    };

    return (
        <div>
            <MenuItemLink
              key='home'
              to='/'
              primaryText='Accueil'
              leftIcon={
                  <AppsIcon/>
              }
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />

            {resources
                .filter(resource => !excludedResources.includes(resource.name))
                .map(resource => {
                    return (<MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={
                            (resource.options && resource.options.label) || resource.name
                        }
                        leftIcon={
                            resource.icon ? resource.icon : <DefaultIcon/>
                        }
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />)
                })}
            {logout}
            <div className={useStyles().footer}>
                {open ?
                    (
                    <div>
                        {permissions && permissions.roles.includes('ROLE_BUSINESS_ADMIN_SUPPLIER') ? (
                        <Form resource="business-consumers" id="me" record={identity} onSubmit={onSubmitMe}>
                            <ReferenceInput source="supplierId" reference="suppliers" perPage={1000} validate={[required()]}>
                                <SelectInput label="Fourn." optionText="name" optionValue="id"/>
                            </ReferenceInput>
                            <SaveButton />
                        </Form>
                        ) : ''}
                        <PrinterConfiguration/>
                    </div>
                    ) : ''
                }
            </div>
        </div>
    );
};

export default Menu;
