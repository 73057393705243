import * as React from 'react'
import {
    SimpleShowLayout,
    Show,
    TopToolbar,
    EditButton,
} from 'react-admin'
import { SupplierIssueItemField } from '../../fields'

export const SupplierIssueShow = () => (
    <Show actions={<SupplierIssueActions source="state"/>}>
        <SimpleShowLayout>
            <SupplierIssueItemField/>
        </SimpleShowLayout>
    </Show>
)

const SupplierIssueActions = ({ basePath, data, source }) => (
    <TopToolbar>
        {source !== 'closed' && <EditButton source={source} record={data} basePath={basePath} label="Répondre"/>}
    </TopToolbar>
)
