import * as React from 'react'
import { FilterList, FilterListItem, useGetList, SavedQueriesList } from 'react-admin'
import inflection from 'inflection'
import { Card as MuiCard, CardContent } from '@mui/material'
import { withStyles } from '@mui/styles'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { shipmentStateList } from './shipmentFilter'

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard)

const ShipmentStateFilterList = () => {
    return (
        <FilterList label="Etat">
            {shipmentStateList.map(shipmentState => {
                return <FilterListItem
                    label={shipmentState.name}
                    key={shipmentState.id}
                    value={{ shipmentState: shipmentState.id }}
                />
            })}
        </FilterList>
    )
}

const ShipmentCarrierFilterList = () => {
    const { data } = useGetList('carriers', {});

    return (
        <FilterList
            label="Transporteur"
            icon={<LocalShippingIcon />}
        >
            {data &&
            data.map((carrier) => (
                <FilterListItem
                    label={inflection.humanize(carrier.name)}
                    key={carrier.id}
                    value={{ carrier: carrier.id }}
                />
            ))}
        </FilterList>
    )
}

export const ShipmentSidebar = (props) => {
    return (
        <Card>
            <CardContent>
                <SavedQueriesList />
                <ShipmentStateFilterList/>
                <ShipmentCarrierFilterList/>
            </CardContent>
        </Card>
    )
}