import React from 'react'
import PropTypes from 'prop-types'
import { useGlobalStyles } from '../../styles';
import {Button, useRecordContext} from 'react-admin'
import GetAppIcon from '@mui/icons-material/GetApp'
import CircularProgress from '@mui/material/CircularProgress'
import { saveAs } from 'file-saver'

export const OrderItemDownloadButton = (props) => {
    const record = useRecordContext(props);
    const label = props.label;
    const classes = useGlobalStyles();
    const [isDownload, setIsDownload] = React.useState()
    const handleClick = (value) => {
        setIsDownload(value)
    }

    const downloadFilesFromOrderItem = (orderItem) => {
        orderItem.documents.filter(doc => doc.type === 'final')
            .map(finalDoc => {
                fetch(finalDoc.path, {
                    method: 'GET',
                    headers: {
                        'Content-Type': finalDoc.mimeType,
                    },
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        return saveAs(blob, finalDoc.name);
                    })

                return finalDoc;
            })
    }

    const orderItemDocumentsAvailable = record && record.documents && record.documents.length;

    return (<div className={classes.wrapper}>
                <Button
                    startIcon={<GetAppIcon/>}
                    label={label ? label : 'Fichiers'}
                    onClick={(e) => {
                        handleClick(true)
                        e.stopPropagation();
                        downloadFilesFromOrderItem(record)
                    }}
                    disabled={!orderItemDocumentsAvailable || isDownload}
                />
                {isDownload && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </div>
    )
};

OrderItemDownloadButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    manufacturing: PropTypes.object,
}
