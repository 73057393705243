import * as React from 'react'
import { TextInput, DateInput } from 'react-admin'

export const ManufacturingFilter = [
    <TextInput label="Id" source="id"/>,
    <DateInput label="Date de création" source="createdAt"/>,
    <TextInput label="ExternalID" source="externalFabricationId"/>,
    <DateInput label="Reçue le" source="scheduledAt"/>,
];

