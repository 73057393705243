import * as React from "react";
import Grid from '@mui/material/Grid';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PhoneIcon from '@mui/icons-material/Phone';
import { useRecordContext } from "react-admin";

export const Address = (props) => {
    const record = useRecordContext(props);
    const address = record.shippingAddress;

    return (
        <Grid container>
            <Grid item xs={12}>
                <span>{address.firstName} {address.lastName}</span>
            </Grid>
            {address.company &&
                <Grid item xs={12}>
                    <BusinessCenterIcon/> <span>{address.company}</span>
                </Grid>
            }
            <Grid item xs={12}>
                <span>{address.street}</span>
            </Grid>
            <Grid item xs={12}>
                <span>{address.postcode} {address.city}</span>
            </Grid>
            <Grid item xs={12}>
                <span>{address.provinceCode} {address.provinceName}</span>
            </Grid>
            {address.phoneNumber &&
                <Grid item xs={12}>
                    <PhoneIcon/> <span>{address.phoneNumber}</span>
                </Grid>
            }
        </Grid>
    )
};
