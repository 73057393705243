import * as React from "react";
import { Layout } from 'react-admin';
import Menu from './menu';
import CustomAppBar from './customAppBar';

export const GlobalLayout = (props) =>
    <Layout
        {...props}
        menu={Menu}
        appBar={CustomAppBar}
    />;
