import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from '../styles';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useRecordContext } from 'react-admin';

export const CustomTextField  = (props) => {
    const record = useRecordContext(props);
    const source = props.source;
    const divided = props.divided;
    const label = props.label;
    const date = props.date;

    const classes = useGlobalStyles();

    return (
        <Grid container>
            {label ?
                <Grid item xs={12}>
                    <span className={classes.gray}>{label}</span>
                </Grid> : ''
            }
            <Grid item xs={12}>
            <span>
            {date ?
                moment(record[source]).format('DD/MM/YYYY')
                : typeof source === "string" ? divided ? record[source] / 100 +' €' : record[source] :
                    source
            }
            </span>
            </Grid>
        </Grid>
    )
};

CustomTextField.propTypes = {
    label: PropTypes.string,
    source: PropTypes.any.isRequired
};
