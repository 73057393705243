import * as React from 'react'
import { List, Datagrid, TextField, ImageField, DateField, ShowButton } from 'react-admin'

export const OrderItemList = (props) => (
    <List {...props} title="Liste des fabrications" perPage={25}>
        <Datagrid>
            <TextField source="id" sortable={false}/>
            <TextField source="state" sortable={false}/>
            <TextField source="externalFabricationId" sortable={false}/>
            <ImageField source="thumbnail" sortable={false}/>
            <DateField source="nearestShippingAt" sortable={false}/>
            <DateField source="createdAt" sortable={false}/>
            <ShowButton/>
        </Datagrid>
    </List>
)