import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

function getFirstAvailableCommentFromSupplierIssueItems(items) {
    if (items) {
        for (const item of items) {
            if (item.comment) {
                return item.comment;
            }
        }
    }

    return null;
}

export const SupplierIssueFirstCommentField = (props) => {
    const record = useRecordContext(props);
    const comment = getFirstAvailableCommentFromSupplierIssueItems(record.items);

    if (!comment) {
        return <div><span>Pas de commentaire</span></div>
    }

    return <div><span>{comment}</span></div>
};

SupplierIssueFirstCommentField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
}
