import * as React from 'react'
import { useCallback } from 'react'
import { useNotify, Button, useListContext, useRecordContext, useDataProvider, usePermissions } from 'react-admin'
import PrintIcon from '@mui/icons-material/Print'
import { useMutation } from 'react-query';

export const directPrintZPL = (shipment, notify) => {
    if (!window.localStorage['defaultPrinterType']) {
        return;
    }
    if (window.localStorage['defaultPrinterType'] === 'network') {
        const isNwJs = typeof require('nw.gui') !== 'undefined';
        if (!isNwJs) {
            notify('vous devez utiliser nw.js pour imprimer en reseau via supplier')
        } else {
            const net = eval('require')('net'); // eslint-disable-line no-eval
            const index = window.localStorage['defaultPrinterNetwork'].lastIndexOf(':');
            const ip = window.localStorage['defaultPrinterNetwork'].substring(0, index);
            const port = window.localStorage['defaultPrinterNetwork'].substring(index + 1);

            const socket = net.Socket();
            let client = socket.connect(port, ip, ()=> {
                const encoder = new TextEncoder()
                let count = 0
                if (shipment.labelContent) {
                    const zpl = atob(shipment.labelContent)
                    client.write(encoder.encode(zpl));
                    count++
                } else {
                    for (const shipmentPackage of shipment.packages) {
                        if (shipmentPackage.labelContent) {
                            const zpl = atob(shipmentPackage.labelContent)
                            client.write(encoder.encode(zpl));
                            count++
                        }
                    }
                }
                document.dispatchEvent(new Event('successPrint'));
                notify(count + ' etiquette(s) envoyée(s) à l\'imprimante', 'info')
                client.end();
            });

            client.on('error', function(ex) {
                notify('erreur de connexion à l\'imprimante reseau', 'warning', { smart_count: 1 }, false)
                console.log(ex);
            });
        }

        return;
    }
    if (!navigator.usb) {
        notify('veuillez utiliser un navigateur compatible WebUSB pour imprimer en USB', 'warning', { smart_count: 1 }, false)

        return;
    }
    navigator.usb.getDevices()
        .then(devices => {
            devices.forEach(device => {
                if (device.productId === parseInt(window.localStorage['defaultPrinter'])) {
                    console.log(device)
                    device
                        .open()
                        .then(() => device.selectConfiguration(1))
                        .then(() => device.claimInterface(device.configuration.interfaces[0].interfaceNumber))
                        .catch((e) => {
                            if (device.configuration.interfaces[1]) {
                                return device.claimInterface(device.configuration.interfaces[1].interfaceNumber)
                            } else {
                                notify(
                                    'impossible de se connecter à l\'imprimante, si vous etes sur windows, utilisez zadig pour remplacer le driver de l\'imprimante',
                                    'warning',
                                    { smart_count: 1 },
                                    false
                                )
                                throw e
                            }
                        })
                        .then(() => {
                            let defaultEndpoint = 1;
                            // Detect out endpoint number.
                            for (const interfacePrinter of device.configuration.interfaces) {
                                if (interfacePrinter.claimed === true) {
                                    for (const endpoint of interfacePrinter.alternate.endpoints) {
                                        if (endpoint.direction === 'out') {
                                            defaultEndpoint = endpoint.endpointNumber;
                                            break;
                                        }
                                    }
                                    break;
                                }
                            }
                            const encoder = new TextEncoder()
                            let count = 0
                            if (shipment.labelContent) {
                                const zpl = atob(shipment.labelContent)
                                console.log(zpl)
                                console.log(device.transferOut(defaultEndpoint, encoder.encode(zpl)))
                                count++
                            } else {
                                for (const shipmentPackage of shipment.packages) {
                                    if (shipmentPackage.labelContent) {
                                        const zpl = atob(shipmentPackage.labelContent)
                                        console.log(zpl)
                                        console.log(device.transferOut(defaultEndpoint, encoder.encode(zpl)))
                                        count++
                                    }
                                }
                            }
                            document.dispatchEvent(new Event('successPrint'));
                            notify(count + ' etiquette(s) envoyée(s) à l\'imprimante', 'info')
                        })
                }
            })
        })
}

export const PrintButton = (props) => {
    const record = useRecordContext(props);
    const notify = useNotify()
    const { selectedIds, data } = useListContext(props);
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const { mutate, isLoading } = useMutation(
        (params) => dataProvider.getMany('shipments', params).then(({ data }) => {
            for (const updatedRecord of data) {
                directPrintZPL(updatedRecord, notify)
            }
        }).catch((error) => {
            notify(
                `shipment print ${record ? record.id : ''} error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
            )
        })
    );

    const handlePrint = useCallback(
        event => {
            event.stopPropagation()
            if (!window.localStorage['defaultPrinterType']) {
                notify(
                    'veuillez connecter et sélectionner une imprimante',
                    'warning'
                )
            } else {
                mutate({ ids: selectedIds && selectedIds.length ? selectedIds : [record.id], query: { labelType: 'zpl' } })
            }
        },
        [mutate, record, notify, selectedIds]
    )

    const noTracking = (!selectedIds || !data || selectedIds.length === 0 || selectedIds.some(selectedId => !data.find(({ id, packages }) => id === selectedId && packages && packages.every(shipmentPackage => shipmentPackage.tracking))))
        && (!record || !record.packages || record.packages.some(shipmentPackage => !shipmentPackage.tracking))
    const transportLabelAccess = permissions && permissions.transportLabelAccess;

    return <div><Button label="Imprimer" variant="containedSecondary" onClick={handlePrint} disabled={!transportLabelAccess || noTracking || isLoading}
                        startIcon={<PrintIcon/>}/></div>
}
