import InputBase from "@mui/material/InputBase";
import * as React from "react";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";


const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    logo: {
        width: 30+'px',
        marginRight: 20+'px',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    displayNone: {
        display: 'none',
    }
}));

export const ShipmentInput = props => {
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [shipment, setShipment] = React.useState(null);
    const [shipmentFilter, setShipmentFilter] = React.useState(null);
    const [shipmentObject, setShipmentObject] = React.useState(null);
    const [shipmentLoading, setShipmentLoading] = React.useState(true);
    const [notifyShipmentEmpty, setNotifyShipmentEmpty] = React.useState(false);
    const [shipmentRedirect, setShipmentRedirect] = React.useState(false);
    const [resetShipment, setResetShipment] = React.useState(false);

    const onChangeShipment = (value) => {
        setShipmentLoading(true);
        if (resetShipment === true) {
            setShipment(null);
            setResetShipment(false);
            document.getElementById('shipmentSearch').value = value.substr(value.length - 1);
        }
        setShipment(value);
    }
    const searchShipment = () => {
        if (shipment.includes('-')) {
            setShipmentFilter({
                'exactItemNumber': shipment
            });
        } else {
            if (shipment.includes('F')) {
                setShipmentFilter({
                    'manufacturingId': shipment.substring(1)
                });
            } else {
                setShipmentFilter({
                    'manufacturingExternalId': shipment
                });
            }
        }
        setResetShipment(true);
    }

    useEffect(() => {
        if (shipmentFilter !== null) {
            dataProvider.getList('shipments', {
                pagination: {page: 1, perPage: 100},
                sort: {},
                filter: shipmentFilter
            })
            .then(({data}) => {
                setShipmentObject(data);
            })
            .then(() => {
                if(shipmentObject.length === 0 && shipmentFilter.exactItemNumber) {
                    setShipmentFilter({
                        'itemNumber': shipment
                    });
                } else if (shipmentObject.length === 0 && shipmentFilter.manufacturingExternalId) {
                    setShipmentFilter({
                        'manufacturingId': shipment
                    });
                } else {
                    if (shipmentObject.length === 0) {
                        setNotifyShipmentEmpty(true);
                    }
                    setShipmentLoading(false);
                    setShipmentRedirect(true);
                }

            })
            .catch(error => {
                setShipmentLoading(false);
            })
            ;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentFilter]);
    const classes = useStyles();

    return (
        <div className={classes.search}>
            <div className={classes.displayNone}>
                {(shipmentRedirect && !shipmentLoading && (shipmentObject.length === 1) && shipmentObject[0].id) && setShipmentRedirect(false) + (window.location=`/#/shipments/${shipmentObject[0].id}`)}
                {(shipmentRedirect && !shipmentLoading && (shipmentObject.length > 1) && shipmentObject[0].id) && setShipmentRedirect(false) + (window.location=`/#/shipments?filter=${JSON.stringify(shipmentFilter)}&page=1&perPage=25`)}
                {(shipmentRedirect && !shipmentLoading && notifyShipmentEmpty) && notify('Pas d\'expédition trouvée', 'warning') + setNotifyShipmentEmpty(false) + setShipmentRedirect(false) + (window.location=`/#/shipments?filter={}`)}
            </div>
            <div className={classes.searchIcon}>
                <LocalShippingIcon />
            </div>
            <InputBase
                placeholder="ID expédition"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                id="shipmentSearch"
                onChange={(newValue) => onChangeShipment(newValue.target.value)}
                onKeyPress={(event) => (event.key === "Enter" && searchShipment())}
            />
        </div>
    );
}