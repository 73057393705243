import InputBase from "@mui/material/InputBase";
import * as React from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import PrintIcon from "@mui/icons-material/Print";

const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    logo: {
        width: 30+'px',
        marginRight: 20+'px',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    displayNone: {
        display: 'none',
    }
}));

export const ManufacturingInput = props => {
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [manufacturing, setManufacturing] = React.useState(null);
    const [manufacturingFilter, setManufacturingFilter] = React.useState(null);
    const [manufacturingObject, setManufacturingObject] = React.useState(null);
    const [manufacturingLoading, setManufacturingLoading] = React.useState(true);
    const [notifyManufacturingEmpty, setNotifyManufacturingEmpty] = React.useState(false);
    const [manufacturingRedirect, setManufacturingRedirect] = React.useState(false);
    const [resetManufacturing, setResetManufacturing] = React.useState(false);

    const onChangeManufacturing = (value) => {
        setManufacturingLoading(true);
        if (resetManufacturing === true) {
            setResetManufacturing(false);
            document.getElementById('manufacturingSearch').value = value.substr(value.length - 1);
        }
        setManufacturing(value);
    }
    const searchManufacturing = () => {
        if (manufacturing.includes('-')) {
            setManufacturingFilter({
                'exactItemNumber': manufacturing
            });
        } else {
            if (manufacturing.includes('F')) {
                setManufacturingFilter({
                    'id': manufacturing.substring(1)
                });
            } else {
                setManufacturingFilter({
                    'externalFabricationId': manufacturing
                });
            }
        }
        setResetManufacturing(true)
    }

    useEffect(() => {
        if (manufacturingFilter !== null) {
            dataProvider.getList('manufacturings', {
                pagination: {page: 1, perPage: 100},
                sort: {},
                filter: manufacturingFilter
            })
            .then(({data}) => {
                setManufacturingObject(data);
            })
            .then(() => {
                if(manufacturingObject.length === 0 && manufacturingFilter.exactItemNumber) {
                    setManufacturingFilter({
                        'itemNumber': manufacturing
                    });
                } else if (manufacturingObject.length === 0 && manufacturingFilter.externalFabricationId) {
                    setManufacturingFilter({
                        'id': manufacturing
                    });
                } else {
                    if (manufacturingObject.length === 0) {
                        setNotifyManufacturingEmpty(true);
                    }
                    setManufacturingLoading(false);
                    setManufacturingRedirect(true);
                }
            })
            .catch(error => {
                setManufacturingLoading(false);
            })
            ;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manufacturingFilter]);
    const classes = useStyles();

    return (
        <div className={classes.search}>
            <div className={classes.displayNone}>
                {(manufacturingRedirect && !manufacturingLoading && (manufacturingObject.length === 1) && manufacturingObject[0].id) && setManufacturingRedirect(false) + (window.location=`/#/manufacturings/${manufacturingObject[0].id}/show`)}
                {(manufacturingRedirect && !manufacturingLoading && (manufacturingObject.length > 1) && manufacturingObject[0].id) && setManufacturingRedirect(false) + (window.location=`/#/manufacturings?filter=${JSON.stringify(manufacturingFilter)}&page=1&perPage=25`)}
                {(manufacturingRedirect && !manufacturingLoading && notifyManufacturingEmpty) && notify('Pas de fabrication trouvée', 'warning') + setNotifyManufacturingEmpty(false) + setManufacturingRedirect(false) + (window.location=`/#/manufacturings?filter={}`)}
            </div>
            <div className={classes.searchIcon}>
                <PrintIcon />
            </div>
            <InputBase
                placeholder="N° Fab. Externe"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                id="manufacturingSearch"
                onChange={(newValue) => onChangeManufacturing(newValue.target.value)}
                onKeyDown={(event) => (event.key === "Enter" && searchManufacturing())}
            />
        </div>
    );
}