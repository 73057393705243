import React from 'react';
import { Button } from 'react-admin';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';

export const BackButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };

    return (
        <div>
            <Button
                startIcon={<ArrowBack />}
                color="primary"
                onClick={handleClick}
                label="Retour"
                variant="containedSecondary"
            />
        </div>
    );
}
