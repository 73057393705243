import {
    Alert,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Modal,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {
    useCreatePath,
    useRecordContext,
    useListContext,
    DatagridRow,
    useListController,
    useUpdate,
    useNotify,
    useRefresh,
} from 'react-admin';
import union from 'lodash/union';
import difference from 'lodash/difference';
import * as React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AddOrRemoveOrderItemButton } from '../../buttons';
import { getBackgroundColorFromHash, getShipmentColorFromState, getShipmentStateTranslation } from './shipmentFilter';
import { VariantField } from '../../fields';
import moment from 'moment';
import { useGlobalStyles } from '../../styles';
import {Inventory} from "@mui/icons-material";

export const ShipmentListPackaging = () => {
    const { data, isLoading } = useListContext();

    const [currentHashDrag, setCurrentHashDrag] = useState('');

    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);

    const listContext = useListController();
    const selectedIds = listContext.selectedIds;
    const onToggleItem = listContext.onToggleItem;
    const onSelect = listContext.onSelect;
    const notify = useNotify();
    const refresh = useRefresh();

    const lastSelected = useRef(null);
    const isRowSelectable = true;

    const [addItemFromShipment] = useUpdate('shipments', {}, {
        onSuccess: () => {
            notify(`order item added to shipment success`, 'info', { smart_count: 1 }, false);
            refresh();
        },
        onError: (error) => {
            notify(
                `shipment edit link orderItems error: ${typeof error === 'object' ? error.message || error.statusText : error}`,
                'warning'
            )
        },
    });

    useEffect(() => {
        if (!selectedIds || selectedIds.length === 0) {
            lastSelected.current = null;
        }
    }, [JSON.stringify(selectedIds)]); // eslint-disable-line react-hooks/exhaustive-deps

    // we manage row selection at the datagrid level to allow shift+click to select an array of rows
    const handleToggleItem = useCallback(
        (id, event) => {
            const ids = data.map(record => record.id);
            const lastSelectedIndex = ids.indexOf(lastSelected.current);
            lastSelected.current = event.target.checked ? id : null;

            if (event.shiftKey && lastSelectedIndex !== -1) {
                const index = ids.indexOf(id);
                const idsBetweenSelections = ids.slice(
                    Math.min(lastSelectedIndex, index),
                    Math.max(lastSelectedIndex, index) + 1
                );

                const newSelectedIds = event.target.checked
                    ? union(selectedIds, idsBetweenSelections)
                    : difference(selectedIds, idsBetweenSelections);

                onSelect(
                    isRowSelectable
                        ? newSelectedIds.filter((id) =>
                            isRowSelectable(
                                data.find(record => record.id === id)
                            )
                        )
                        : newSelectedIds
                );
            } else {
                onToggleItem(id);
            }
        },
        [data, selectedIds, isRowSelectable, onSelect, onToggleItem]
    );

    const handleClose = () => {
        setOpen(false);
    };

    const handleImage = (orderItem) => {
        const imagesPath = getThumbnailFromOrderItem(orderItem, false);
        console.log(imagesPath);
        if (imagesPath.length) {
            setImages(imagesPath);
            setOpen(true);
        }
    };

    // we use the raw dataProvider to avoid too many updates (which would create junk)
    // const dataProvider = useContext(DataProviderContext);

    if (isLoading) return null;

    const onDragStart = (draggable) => {
        const shipment = data.find(record => record.id === parseInt(draggable.source.droppableId));
        if (shipment && shipment.shippingAddress) {
            setCurrentHashDrag(shipment.shippingAddress.hash);
        } else {
            setCurrentHashDrag('');
        }
    }

    const onDragEnd = async result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId) {
            return;
        }

        const shipment = data && data.find(record => record.id === parseInt(destination.droppableId));
        if (!shipment) {
            return;
        }

        addItemFromShipment('shipments', { id: destination.droppableId, data: { addOrderItemIds: [draggableId] } });
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}>
                    {data && data.map(shipment => (
                        <DatagridRow rowClick='toggleSelection' hasBulkActions={true} onToggleItem={handleToggleItem} id={shipment.id} key={shipment.id} selected={selectedIds?.includes(shipment.id)}>
                            <ShipmentsOrderItems
                                record={shipment}
                                handleImage={handleImage}
                                currentHashDrag={currentHashDrag}
                            />
                        </DatagridRow>
                    ))}
                </Box>
            </DragDropContext>
            <Modal
                open={open}
                onClose={handleClose}
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                closeAfterTransition
            >
                <div>
                    {images.map((image) => (
                        <img
                            src={image}
                            alt="pas d'aperçu disponible"
                            style={{ maxHeight: "95%", maxWidth: "95%" }}
                        />
                    ))}
                </div>
            </Modal>
        </>
    );
};

const getThumbnailFromOrderItem = (orderItem, first = true) => {
    let images = [];

    if (orderItem.documents) {
        const doc = orderItem.documents.find(doc => doc.type === "final");

        if (doc && doc.thumbnailDocuments.length) {
            if (first) {
                return doc.thumbnailDocuments[0].path;
            }
            for (let i = 0; i < doc.thumbnailDocuments.length; i++) {
                images.push(doc.thumbnailDocuments[i].path);
            }
        }
    }

    return first ? null : images;
};

const useColsForWidth = () => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));
    // there are all dividers of 24, to have full rows on each page
    if (xl) return 8;
    if (lg) return 6;
    if (md) return 4;
    if (sm) return 3;
    return 2;
};

const ShipmentsOrderItems = (props) => {
    const record = useRecordContext(props)
    const cols = useColsForWidth();
    const createPath = useCreatePath();
    const handleImage = props.handleImage;
    const classes = useGlobalStyles();
    const currentHashDrag = props.currentHashDrag;

    return (
        <Box
            sx={{
                paddingTop: '8px',
                paddingBottom: '16px',
                bgcolor: record.shippingAddress ? getBackgroundColorFromHash(record.shippingAddress.hash) : '#FF0000',
                paddingLeft: '5px',
                paddingRight: '5px',
                margin: '5px',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                height: '510px',
            }}
        >
            <Droppable droppableId={String(record.id)} key={record.id} isDropDisabled={!record.shippingAddress || currentHashDrag !== record.shippingAddress.hash}>
                {(droppableProvided, snapshot) => (
                    <Box
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                        className={
                            (snapshot.isDraggingOver ? ' isDraggingOver' : (snapshot.isDropDisabled ? ' isDropDisabled' : ''))
                        }
                        sx={{
                            width: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            padding: '0',
                            '&.isDraggingOver': {
                                bgcolor: '#dadadf',
                            },
                            '&.isDropDisabled': {
                                opacity: '0.1',
                                bgcolor: '#ffffff',
                            },
                            height: '250px',
                            overflowY: 'hidden',
                        }}
                    >
                        <ImageList rowHeight={245} cols={cols} sx={{ m: 0 }} display="flex">
                            {record.orderItems.map((orderItem, index) => {
                                return (
                                    <Draggable draggableId={String(orderItem.id)} index={index} key={orderItem.id}>
                                        {(provided, snapshot) => (
                                            <ImageListItem
                                                key={orderItem.id}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                elevation={snapshot.isDragging ? 3 : 1}
                                            >
                                                <img
                                                    src={`${getThumbnailFromOrderItem(orderItem)}`}
                                                    alt={orderItem.number}
                                                    onClick={(e) => {
                                                        handleImage(orderItem);
                                                        e.stopPropagation();
                                                    }}
                                                    style={{ width: '200px', cursor: 'pointer' }}
                                                />
                                                {orderItem.delay <= 3 ? (
                                                    <div style={{ position: 'absolute', top:0, right: '5px', background: 'yellow', padding: '5px' }}>
                                                        J+{orderItem.delay}
                                                    </div>
                                                ) : ''}
                                                {orderItem.canSendPrintoclockAdvertising ? (
                                                    <div style={{ position: 'absolute', top:0, left: 0, background: 'pink', padding: '5px' }}>
                                                        <Inventory sx={{ color: orderItem.adPackageCampaign.color }} />
                                                    </div>
                                                ) : ''}
                                                <ImageListItemBar
                                                    title={<Link style={{ textDecoration: 'none', color: '#ffffff', }} to={createPath({
                                                        resource: 'order-items',
                                                        id: orderItem.id,
                                                        type: 'show',
                                                    })}>{orderItem.number}</Link>}
                                                    subtitle={<VariantField record={orderItem} />}
                                                    align="center"
                                                    sx={{
                                                        // cursor: 'pointer',
                                                        overflow: 'scroll',
                                                        background: '#000',
                                                        '.MuiImageListItemBar-subtitle': {
                                                            whiteSpace: 'normal',
                                                        },
                                                    }}
                                                    actionIcon={record.orderItems.length > 1 ? <AddOrRemoveOrderItemButton record={orderItem} label={false} type="remove" shipmentId={record.id}/> : false}
                                                />

                                            </ImageListItem>
                                        )}
                                    </Draggable>
                                )
                            })}
                        </ImageList>
                        {droppableProvided.placeholder}
                    </Box>
                )}
            </Droppable>
            <Box sx={{ bgcolor: getShipmentColorFromState(record.state), borderRadius: 5 }}>
                <Typography
                    align="center"
                    variant="subtitle1"
                    component={Link}
                    color="primary"
                    to={createPath({
                        resource: 'shipments',
                        id: record.id,
                        type: 'show',
                    })}
                    sx={{ textDecoration: 'none' }}
                >
                    <div>{getShipmentStateTranslation(record.state)}</div>
                    <div>{record.orderItems.length} sous-commande{record.orderItems.length > 1 ? 's' : ''}</div>
                    <div>{record.packages.length} paquet{record.packages.length > 1 ? 's' : ''} - {Math.round((record.weight + Number.EPSILON) * 100) / 100} kg</div>
                    <div className={`${classes.small}`}>
                        Exp: {moment(record["estimatedShippedDate"]).format('DD/MM/YYYY')}<br />
                        Liv: {moment(record["estimatedDeliveryDate"]).format('DD/MM/YYYY')}<br />
                        <span style={{ color: '#000', fontWeight: 'bold' }}>{ record.carrier.toUpperCase() }</span>
                    </div>
                </Typography>
                <div onClick={(e) => e.stopPropagation()} sx={{ whiteSpace: 'normal' }}>
                    {record.orderItems.every(orderItem => orderItem.canSendPrintoclockAdvertising) ? <Alert severity="error" icon={<Inventory sx={{ color: record.orderItems[0].adPackageCampaign.color }} />}>{record.orderItems[0].adPackageCampaign.name}</Alert> : ''}
                    {record.orderItems.some(orderItem => orderItem.hasFinishing) ? <Alert severity="info">Finition</Alert> : ''}
                </div>
            </Box>
        </Box>
    )
}