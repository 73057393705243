import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from '../styles';
import { useRecordContext } from 'react-admin';

export const ThumbnailField  = (props) => {
    const classes = useGlobalStyles();
    const record = useRecordContext(props);
    const marge = props.marge;
    const onClick = props.onClick;

    return (
        <div>
            {record["documents"] !== undefined && record["documents"]
                .filter(doc => doc.type === "final")
                .map(function(doc, j) {
                        if (!doc.thumbnailDocuments.length) {
                            return <span key={j}>Pas d'aperçu disponible</span>;
                        }
                        return doc.thumbnailDocuments.map((thumbnail, i) =>
                            <>
                                <img onClick={onClick} key={i} src={thumbnail.path} alt={thumbnail.name} className={marge ? classes.thumbnailMarge : classes.thumbnail}/>
                                <br />
                            </>
                        )
                    }
                )
            }
        </div>
    )
};

ThumbnailField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    onClick: PropTypes.func,
};
