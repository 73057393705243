import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStyles } from '../styles';
import { useRecordContext } from 'react-admin';

export const ProductField  = (props) => {
    const classes = useGlobalStyles();
    const record = useRecordContext(props);
    const centerField = props.centerField;
    const grayName = props.grayName;
    const inline = props.inline;

    return (
        <div className={centerField && classes.center }>
            {record["productCode"]}
            {inline ? ' - ' : <br/>}
            <span className={grayName && classes.gray }>
            {record["productName"]}
        </span>
        </div>
    )
};

ProductField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
