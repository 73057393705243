import * as React from 'react';
import { useGlobalStyles } from '../styles';
import { Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import PrintIcon from '@mui/icons-material/Print';
import { useState, useEffect } from 'react';
import { useDataProvider, Loading, useGetIdentity } from 'react-admin';

export const ManufacturingCard = () => {
  const classes = useGlobalStyles();
  const dataProvider = useDataProvider();
  const [supplier, setSupplier] = useState();
  const [loading, setLoading] = useState(true);
  const { data: identity, isLoading: identityLoading } = useGetIdentity();

  useEffect(() => {
    if (identity) {
      dataProvider.getOne('suppliers', {id: identity.supplierId})
          .then(({ data }) => {
            setSupplier(data);
            setLoading(false);
          }).catch( error => {
        console.log(error);
        setLoading(false);
      })
    }
  }, [identity, dataProvider]);

  if (loading || identityLoading) return <Loading />;
  if (!supplier) return null;

  return (<Card>
    <CardContent>
      <Grid container alignItems="center">
        <Grid container item xs={9}>
          <Grid item xs={12} className={classes.title} id="manufacturingInProgress">
            {supplier.manufacturingInProgress}
          </Grid>
          <Grid item xs={12} className={classes.gray}>
            Fabrications à traiter
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <PrintIcon className={classes.iconDashboard}/>
        </Grid>
      </Grid>
    </CardContent>
  </Card>);
};

