import * as React from 'react';
import {AppBar} from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { ManufacturingInput} from '../input';

const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    logo: {
        width: 30+'px',
        marginRight: 20+'px',
    },
}));

const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props}>
            <img src="logo_poc.png" alt="logo" className={classes.logo}/>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <ManufacturingInput/>
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;