import { useRecordContext, Button } from 'react-admin';
import { Link } from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

export const LinkToRelatedShipments = () => {
    const record = useRecordContext();
    return record ? (
        <Button
            color="primary"
            component={Link}
            to={{
                pathname: '/shipments',
                search: `filter=${JSON.stringify({ itemNumber: record.number, printoscan: true })}`,
            }}
            startIcon={<LocalShippingIcon />}
            label="Expéditions"
        />
    ) : null;
};
