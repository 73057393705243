import * as React from 'react'
import {
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin'

export const SupplierIssueCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput multiline source="comment" />
        </SimpleForm>
    </Create>
)
