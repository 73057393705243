import * as React from 'react'
import {
    AutocompleteInput,
    BooleanInput,
    TextInput,
    DateInput,
} from 'react-admin'

export const shipmentStateList = [
    { id: 'ready', name: 'Prêt', color: '#eaeaee' },
    { id: 'labeled', name: 'Etiqueté', color: '#CBC3E3' },
    { id: 'shipped', name: 'Expédié', color: '#89CFF0' },
    { id: 'delivered', name: 'Livré', color: '#90ee90' },
    { id: 'stored', name: 'Stocké', color: '#964B00' },
    { id: 'not_delivered', name: 'Non livré', color: '#FFCCCB' },
    { id: 'cancelled', name: 'Annulé', color: '#FFFFE0' },
];

export const ShipmentFilter = [
    <BooleanInput label="Condit." source="printoscan" alwaysOn />,
    <TextInput label="Id" source="shipmentId" resettable />,
    <TextInput label="N° Sous-commande" source="itemNumber" resettable alwaysOn/>,
    <TextInput label="Num fab" source="manufacturingId" resettable alwaysOn/>,
    <TextInput label="Ref fab ext." source="manufacturingExternalId" resettable alwaysOn/>,
    <AutocompleteInput label="Statut" source="shipmentState" alwaysOn choices={shipmentStateList}/>,
    <DateInput label="Date d'exp." source="estimatedShippedDate"/>,
    <DateInput label="Date de liv." source="estimatedDeliveryDate"/>,
];

export const getShipmentStateTranslation = (recordState) => {
    const state = shipmentStateList.find(({ id } ) => id === recordState);
    if (state) {
        return state.name;
    }

    return recordState;
}

export const getShipmentColorFromState = (recordState) => {
    const state = shipmentStateList.find(({ id } ) => id === recordState);
    if (state) {
        return state.color;
    }

    return '#eaeaee';
}

export const getBackgroundColorFromHash = (recordHash) => {
    if (recordHash) {
        // let stringUniqueHash = [...recordHash].reduce((acc, char) => {
        //     return char.charCodeAt(0) + ((acc << 5) - acc);
        // }, 0);
        // return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
        let hash = 0;
        for (let i = 0; i < recordHash.length; i++) {
            hash = recordHash.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }

        return colour;
    }

    return '#eaeaee';
}